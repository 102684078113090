
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import LoginPage from "./components/portal/authentication/login";
import Dashboard from "./components/portal/dashboard/dashboard";
import ProtectedRoute from "./components/portal/authentication/route/protectedroute";
/*
import {
	getCurrentUser,
	getCurrentUserProfileByEmail,
} from "./services/authentication/auth";
*/
import { ToastContainer } from "react-toastify";
import LogOut from "./components/portal/authentication/logout";
import NavigationBar from "./components/portal/navigation/navigationbar";
//import MyProperties from "./components/portal/properties/myproperties";
import config from "../src/constants/config.json";
//import PropertyDetail from "./components/portal/properties/propertydetail";
//import PropertyUnitDetail from "./components/portal/properties/units/propertunitdetail";
import PrimaryApplicationForm from "./components/portal/primary/primaryApplicationForm";
import PropertyUnitAdvert from "./components/public/advertisement/profile/propertyUnitAdvert";
import TenantSignUpManagement from "./components/public/rentalSignUp/tenantSignUpManagement";
import { getCurrentUser, getCurrentUserProfileByEmail } from "./services/pre-screening/prescreeningManagementService";



//TODO next need to show the advertisement screen
//https://tenant.gurenter.com/advert/unit30a
//Put a contact us to be able to contact the landlord via the platform if they have any questions 
//(can be blocked by the landlord)
//Need to provide an overview of what the steps are to rent the property, so the tenant is aware ahead
function App() {
	const [isDarkMode, setIsDarkMode] = useState(false);
	const [userToken, setUserToken] = useState("");
	//get and set the landlord profile (use redux to update this)
	const [tenantProfile, setTenantProfile] = useState({
		tenantId: "",
	
		firstName: "",
		//middleName: "",
		lastName: "",
		emailAddress: "",
		//signatureUrl: "",
	});
	//TODO: add the register new landlord feature last so that i can add all the other features


  useEffect(() => {
	//dark mode start
	const theme = isDarkMode ? 'dark' : 'light';
	document.documentElement.setAttribute('data-bs-theme', theme);
	//dark mode end
	getUserToken();
	getTenantProfile();
	

	

  }, [isDarkMode]);


	const getUserToken = async () => {
		try {
			const userToken = await getCurrentUser();
		
			if (!userToken) return null;
			
			setUserToken({ userToken });

			localStorage.setItem(config.token_key, userToken);

		} catch (error) {
			console.error(`Error getting user token: ${error}`);
			// Handle the error appropriately here
		}
	};

	//TODO: for tenant only
	const getTenantProfile = async () => {
		const email = localStorage.getItem(config.email_key);

		// If email is null or an empty string, return immediately
		if (!email) return;

		console.log(`email: ${email}`);
		const { data: tenantProfile } = await getCurrentUserProfileByEmail(email);
		console.log("Result");
		console.log(tenantProfile.firstName);

		if (!tenantProfile) return null;

		localStorage.setItem(config.user_key, tenantProfile.tenantId);
		setTenantProfile({
			tenantId: tenantProfile.tenantId,
			firstName: tenantProfile.firstName,
			lastName: tenantProfile.lastName,
			emailAddress: tenantProfile.emailAddress,
		});
	};

	 

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

	return (
		<React.Fragment   >
			<ToastContainer />
			<div >
				<div id="layout-wrapper"  >
					{/*<NavBar /> need a protected view only show the nav when logged in*/}
					<div className="navbar-header">
					<NavigationBar tenantProfile={tenantProfile} isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
				</div>

					 <div className="vertical-overlay"></div>
					 <div  className="layout-width" >
						<Routes>
							<Route path='/' element={<LoginPage />} />
							{/*<ProtectedRoute
							user={userToken}
							path='/dashboard'
							element={<Dashboard />}
  />*/}

  	<Route path="/advert" element={<PropertyUnitAdvert />} >
		<Route path=":campaignId" element={<PropertyUnitAdvert />} />
	</Route>

	
	<Route path="/rentalSignUp" element={<TenantSignUpManagement />} >
		<Route path=":campaignId" element={<TenantSignUpManagement />} />
	</Route>

							<Route
								path='/dashboard'
								element={
									<ProtectedRoute user={userToken} redirectPath='/'>
										<Dashboard tenantProfile={tenantProfile} isDarkMode={isDarkMode} />
									</ProtectedRoute>
								}
							/>
							<Route
								path='/primaryApplicationForm'
								element={
									<ProtectedRoute user={userToken} redirectPath='/'>
										<PrimaryApplicationForm isDarkMode={isDarkMode} />
									</ProtectedRoute>
								}
							/>
							
			{/*				<Route
								path='/myproperties'
								element={
									<ProtectedRoute user={userToken} redirectPath='/'>
										<MyProperties tenantProfile={tenantProfile} />
									</ProtectedRoute>
								}
							/>

							<Route
								path='/newproperty'
								element={
									<ProtectedRoute user={userToken} redirectPath='/'>
										<CreateNewProperty tenantProfile={tenantProfile} />
									</ProtectedRoute>
								}
							/>
							 PropertyUnitDetail	<Route
								path='/property'
								element={<PropertyDetail tenantProfile={tenantProfile} />}>
								<Route
									path=':propertyId'
									element={
										<ProtectedRoute user={userToken} redirectPath='/'>
											<PropertyDetail tenantProfile={tenantProfile} />
										</ProtectedRoute>
									}
								/>
							</Route>*/}
						{/*	<Route
								path='/property'
								element={<ProtectedRoute user={userToken} />}>
								<Route
									path=':propertyId'
									element={<PropertyDetail tenantProfile={tenantProfile} />}
								/>
							</Route>
							<Route
								path='/propertyunit'
								element={<ProtectedRoute user={userToken} />}>
								<Route
									path=':propertyUnitId'
									element={
										<PropertyUnitDetail tenantProfile={tenantProfile} />
									}
								/>
							</Route>
								*/}

							{/*	
							<Route path='/property' element={<PropertyDetail />}>
								<Route
									path=':propertyId'
									element={
										<ProtectedRoute user={userToken} redirectPath='/'>
											<PropertyDetail tenantProfile={tenantProfile} />
										</ProtectedRoute>
									}
								/>
							</Route>
						

								<Route path='/service' element={<ServiceDetail />}>
							<Route path=':title' element={<ServiceDetail />} />
						</Route>

						<Route element={<ProtectedRoute user={userToken} />}>
						
							<Route path='/dashboard' element={<Dashboard />} />
						</Route>
						<Route
							path='/dashboard'
							element={
								<ProtectedRoute user={userToken}>
									<Dashboard />
								</ProtectedRoute>
							}
						/>
					
						<Route path='/contactus' element={<ContactUs />} />
						<Route path='/tenantapplication' element={<TenantApplication />} />
						<Route path='/blogs' element={<Blogs />} />
						<Route path='/blog' element={<BlogDetail />} />
						<Route path='/*' element={<NotFoundPage />} />*/}
							<Route path='/logout' element={<LogOut />} />
						</Routes>

						{/*	<GeneralFooter />*/}
						            <footer className="footer ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <script>document.write(new Date().getFullYear())</script> © Gurenter LLC.
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end d-none d-sm-block">
                                Design & Develop by Gurenter LLC
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default App;

const Home = () => {
	return <h2>Home (Protected: authenticated user required)</h2>;
};
