import React from "react";

function CheckboxInput({ name, label, checked, onChange, disabled = false }) {
  return (
    <div>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div className="d-flex flex-column">
        <div className="form-check form-switch form-switch-right form-switch-md">
          <input
            className="form-check-input code-switcher"
            type="checkbox"
            id={name}
            name={name}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

export default CheckboxInput;
