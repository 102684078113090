import React, { useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import { Bars } from 'react-loader-spinner';
import { Link, useParams } from 'react-router-dom';
import Form from '../../reusable/form/form';
import Joi from 'joi-browser';

import Input from '../../reusable/form/Input';

import { toast } from 'react-toastify';
import TextInputLabel from '../../reusable/form/textinputlabel';
import CheckboxInput from '../../reusable/form/checkboxInput';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import DatePicker from 'react-datepicker';
import { fetchPropertyAdvertById } from '../../../services/property/advertisement/propertyAdvertisement';
import { createTenantPreScreening } from '../../../services/pre-screening/prescreeningManagementService';
const { v4: uuidv4 } = require('uuid');

//each adult must sign up and fill out the information, each will be given a invite code which they will use to link their applications to be a part of the same pool of co-occupants, when adult added it will send the
//an email (when the user clicks send invite) to the other applicant, letting them sign up as a co-occupant
const TenantSignUpManagement = ({ isDarkMode }) => {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const { campaignId } = useParams();

  const navigate = useNavigate();
  const [data, setData] = useState({
    emailAddress: '',
    password: '',
    firstName: '',
    lastName: '',
    durationOfCurrentResidence: 0,
    totalMonthlyIncome: 0,
    sourceOfIncome: '',
    numberOfOccupants: 0,
    reasonForLeaving: '',
    desiredMoveInDate: new Date(),
    doesAnyOneSmoke: false,
    hasPets: false,
    describePets: '',
  });

  const [propertyUnitAdvert, setPropertyUnitAdvert] = useState({
    advertId: '',
    landlordId: '',
    propertyUnitId: '',
    dateAdvertEnds: '',
    campaignId: '',
    propertyUnit: {
      propertyUnitId: '',
      rentalPropertyId: '',
      unitName: '',
      rentReceiptSequence: 0,
      rentalUnitPrice: 0,
      rentalUnitAmtCurrency: 'USD',
      unitType: '',
      unitCurrentRentalStatus: 'Vacant',
      unitSpecifications: {
        numberOfBedrooms: 0,
        unitSquareFootage: 0,
        parkingDetail: {
          drivewayType: '', //paved, gravel, dirt
          parkingSpace: 0,
        },
        numberOfRooms: 0,
        numberOfBathrooms: 0,
        numberOfHalfBathroom: 0,

        hasKitchen: false,
        hasPatios: false,
        hasPorch: false,
        hasDiningRoom: false,
        hasHalfBathrooms: false,
        hasLivingRoom: false,
        hasCarGarage: false,
      },
      petSpecifications: {
        allowPets: false,
        maxNumberOfPetsAllowed: 0,
        petsAllowed: [
          {
            animalName: '',
            animalType: '',
            animalWeightMax: 0,
          },
        ],
      },
      dateLastRented: '',
      recordCreatedDate: '',
      isRented: false,
      isActivated: true,
    },
    unitAdvertImageUrls: [''],
    securityDeposit: 0,
    currencyId: '',
    currencyName: '',
    isAdvertExpired: false,
  });

  const [errors, setErrors] = useState({});
  const schema = {
    emailAddress: Joi.string().email().required().label('Email Address'), //use joi in node as well
    password: Joi.string().min(7).required().label('Password'),
    firstName: Joi.string().min(2).required().label('First Name'),
    lastName: Joi.string().min(2).required().label('Last Name'),
    durationOfCurrentResidence: Joi.number()
      .min(0)
      .required()
      .label('Duration of Current Residence'),
    totalMonthlyIncome: Joi.number()
      .min(0)
      .required()
      .label('Total Monthly Income'),
    sourceOfIncome: Joi.string().min(2).required().label('Source of Income'),
    numberOfOccupants: Joi.number()
      .min(0)
      .required()
      .label('Number of Occupants'),
    reasonForLeaving: Joi.string()
      .min(2)
      .required()
      .label('Reason for Leaving'),
    desiredMoveInDate: Joi.date().required().label('Desired Move In Date'),
    doesAnyOneSmoke: Joi.boolean().required().label('Does Anyone Smoke?'),
    hasPets: Joi.boolean().required().label('Does Anyone Have Pets?'),
    //describe is only required if hasPets is true
    describePets: Joi.when('hasPets', {
      is: true,
      then: Joi.string().min(2).required().label('Describe Pets'),
      otherwise: Joi.string().allow('').optional().label('Describe Pets'),
    }),
  };

  const [Loading, setLoading] = useState({
    isLoading: false,
  });

  useEffect(() => {
    setLoading({ isLoading: true });
    window.scrollTo(0, 0);

    //load data
    getAdvertDetail();
    //after loading
    setLoading({ isLoading: false });
  }, []);

  const getAdvertDetail = async () => {
    //get the advert details
    const { data: advertDetail } = await fetchPropertyAdvertById(campaignId);
    console.log(advertDetail);
    setPropertyUnitAdvert(advertDetail);
  };

  //TODO: Form Validation (Need to find a way to make this more streamlined)
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();
    // console.log(errors);
    //show the toasts for the errors
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key];
        toast.error(element);
      }
    }

    //toast.error(errors.password);
    setErrors({ errors: errors || {} });
    if (errors) return;

    //console.log(data.desiredMoveInDate);

    doSubmit();
  };

  function handleChange(event) {
    const { value, type, checked } = event.target;
    const name = event.target.name || event.target.id;

    let errorMessage = '';
    let newData = { ...data };

    if (type === 'checkbox') {
      newData[name] = checked;
    } else if (name === 'desiredMoveInDate') {
      newData[name] = new Date(value).toISOString();
    } else {
      errorMessage = validateProperty(event.target);
      newData[name] = value;
    }

    setErrors((prevState) => ({
      ...prevState,
      [name]: errorMessage,
    }));

    setData(newData);
  }

  const doSubmit = async () => {
    //login function
    try {
      //const { data } = this.state;
      //FORMAT TO MATCH THE DETAILS NEEDED FROM THE END POINT
      const prescreeningDetail = {
        emailAddress: data.emailAddress,

        firstName: data.firstName,
        lastName: data.lastName,
        durationOfCurrentResidence: data.durationOfCurrentResidence,
        totalMonthlyIncome: data.totalMonthlyIncome,
        sourceOfIncome: data.sourceOfIncome,
        numberOfOccupants: data.numberOfOccupants,
        reasonForLeaving: data.reasonForLeaving,
        desiredMoveInDate: data.desiredMoveInDate,
        doesAnyOneSmoke: data.doesAnyOneSmoke,
        hasPets: data.hasPets,
        describePets: data.describePets,
      };

      const tenantProfile = {
        tenantId: uuidv4(),
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        password: data.password,
        mainTenantImageUrl: '',
        preScreeningDetail: prescreeningDetail,
      };

      console.log(`Unit ID: ${propertyUnitAdvert.propertyUnitId}`);
      const prescreenFullApplication = {
        campaignId: campaignId, //set from the campaign info
        landlordId: propertyUnitAdvert.landlordId, //set from the campaign info
        propertyUnitId: propertyUnitAdvert.propertyUnitId, //set from the campaign info
        tenantProfile: tenantProfile,
        preScreeningDetail: prescreeningDetail,
      };

      console.log(tenantProfile);
      //TODO: need the endpoint to be created
      const { data: response } = await createTenantPreScreening(
        prescreenFullApplication
      );

      console.log('Response');
      console.log(response);

      //Need to add the above data into
      //tenant and its prescreeningDetails (master), also the
      //tenantRentalApplicationSnapshotScehma (copy)
      //snapshot prescreening cant be adjusted,
      //when the entire snapshot submitted the tenant cant change it
      //full application
      // NEXT;
      //console.log(loginPayLoad);
      //alert("Login called");
      //return;
      //const test = await authLoginLandlord(loginPayLoad);
      //Not working
      // alert(`Login called ${test}`);

      //remove this after testing

      toast.success('Submitted Successfully');

      //force update
      forceUpdate();

      //force a reload of the page
      window.location = '/dashboard';

      navigate('/dashboard');
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.emailAddress = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {Loading.isLoading && (
        <div
          id='global-loader'
          style={{
            zIndex: '99',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'absolute',
          }}
        >
          <Bars
            height='80'
            width='80'
            color='#4fa94d'
            ariaLabel='bars-loading'
            wrapperStyle={{}}
            wrapperclassName=''
            visible={true}
          />
        </div>
      )}
      {/*/Loader */}
      {!campaignId ? (
        <div
          className='page-content'
          data-bs-theme={isDarkMode ? 'dark' : 'light'}
          style={{ paddingBottom: '10%' }}
        >
          <div class='row'>
            <div class='col-xl-8' id='card-slide'>
              <div className='alert alert-warning' role='alert'>
                <h4 className='alert-heading'>Missing Campaign ID</h4>
                <p>
                  A Campaign ID is required in the URL to present an active
                  property unit for rent. Please ensure a valid Campaign ID is
                  provided.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='main-content'>
          <div className='page-content'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='card'>
                    <div className='card-header align-items-center d-flex'>
                      <h4 className='card-title mb-0 flex-grow-1'>
                        Rental Application Pre-Screening Form
                      </h4>
                    </div>
                    {/* end card header */}
                    <form onSubmit={handleSubmit} className='card-body'>
                      <div className='live-preview'>
                        <div className='row gy-4'>
                          {/*TextField start*/}

                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <TextInputLabel
                                name='firstName'
                                value={data.firstName}
                                label='First Name'
                                error={errors.firstName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*TextField end*/}
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <TextInputLabel
                                name='lastName'
                                value={data.lastName}
                                label='Last Name'
                                error={errors.lastName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <TextInputLabel
                                name='emailAddress'
                                value={data.emailAddress}
                                label='Email Address'
                                error={errors.emailAddress}
                                onChange={handleChange}
                                type={'email'}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <TextInputLabel
                                name='password'
                                value={data.password}
                                label='Password'
                                error={errors.password}
                                onChange={handleChange}
                                type={'password'}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <TextInputLabel
                                name='durationOfCurrentResidence'
                                value={data.durationOfCurrentResidence}
                                label='Duration of Current Residence (in months)'
                                error={errors.durationOfCurrentResidence}
                                onChange={handleChange}
                                type={'number'}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <TextInputLabel
                                name='totalMonthlyIncome'
                                value={data.totalMonthlyIncome}
                                label='Household Monthly Income'
                                error={errors.totalMonthlyIncome}
                                onChange={handleChange}
                                type={'number'}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <TextInputLabel
                                name='sourceOfIncome'
                                value={data.sourceOfIncome}
                                label='Source of Income'
                                error={errors.sourceOfIncome}
                                onChange={handleChange}
                                type={'text'}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <TextInputLabel
                                name='numberOfOccupants'
                                value={data.numberOfOccupants}
                                label='Total Number of Occupants'
                                error={errors.numberOfOccupants}
                                onChange={handleChange}
                                type={'number'}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <TextInputLabel
                                name='reasonForLeaving'
                                value={data.reasonForLeaving}
                                label='Reason for Leaving'
                                error={errors.reasonForLeaving}
                                onChange={handleChange}
                                type={'textarea'}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <Input
                                showIcon
                                name='desiredMoveInDate'
                                selected={data.desiredMoveInDate}
                                label='Desired Move In Date'
                                error={errors.desiredMoveInDate}
                                onChange={handleChange}
                                type={'date'}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <CheckboxInput
                                name='doesAnyOneSmoke'
                                label='Does Any of the Occupants Smoke?'
                                checked={data.doesAnyOneSmoke}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          <div className='col-xxl-3 col-md-6'>
                            <div>
                              <CheckboxInput
                                name='hasPets'
                                label='Do you have any pets?'
                                checked={data.hasPets}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {/*end col*/}
                          {data.hasPets && (
                            <div className='col-xxl-3 col-md-6'>
                              <div>
                                <TextInputLabel
                                  name='describePets'
                                  value={data.describePets}
                                  label='Please Describe Your Pets'
                                  error={errors.describePets}
                                  onChange={handleChange}
                                  type={'textarea'}
                                />
                              </div>
                            </div>
                          )}
                          {/*end col*/}
                        </div>
                        {/*end row*/}
                        {/*Register Button*/}
                        <div className='row gy-4' style={{ paddingTop: '2%' }}>
                          <div className='col-lg-12'>
                            <div className='d-grid'>
                              <button
                                className='btn btn-primary'
                                disabled={validate()}
                                type='submit'
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TenantSignUpManagement;
