import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

//import profileImg from "../../../styletheme/images/users/avatar-1.jpg";
import profileImg from '../../../styletheme/images/users/multi-user.jpg';
//import logo from "../../../styletheme/assets/img/brand/logo.png";
//import logo_white from "../../../styletheme/assets/img/brand/logo-white.png";
import config from '../../../constants/config.json';
//import "../../../styletheme/assets/css/menu/navstyles.css";
//import user_img from "../../../styletheme/assets/img/users/6.jpg";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../../../styletheme/logos/gurenter_logo.png';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';
import { getCurrentUser } from '../../../services/pre-screening/prescreeningManagementService';

const NavigationBar = (props) => {
  const { tenantProfile, isDarkMode, toggleDarkMode } = props;

  const [cartItems, setCartItems] = useState(5);
  const [notifications, setNotifications] = useState(3);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };
  /*
 <li className="nav-item">
                                        <a href="apps-calendar.html" className="nav-link" data-key="t-calendar"> Calendar </a>
                                    </li>
*/
  return (
    <React.Fragment>
      <Navbar
        id='page-topbar'
        collapseOnSelect
        expand='lg'
        bg={isDarkMode ? 'dark' : 'medium'}
        variant={isDarkMode ? 'dark' : 'medium'}
        //bg={isDarkMode ? "dark" : "medium"}
        // variant={isDarkMode ? "dark" : "medium"}
        style={{ paddingLeft: '10px', paddingRight: '10px' }}
      >
        <Navbar.Brand as={Link} to='/dashboard' style={{ paddingLeft: '10px' }}>
          <img
            src={logo}
            width='150'
            height='70'
            className='d-inline-block align-top'
            alt='Logo'
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          style={{ paddingRight: '10px' }}
        />
        {!getCurrentUser() && (
          <Navbar.Collapse
            id='responsive-navbar-nav'
            style={{ justifyContent: 'right' }}
          >
            <Nav className='ml-auto fs-5'>
              <Nav.Link as={Link} to='/' className='nav-link '>
                Login
              </Nav.Link>
            </Nav>

            <Nav className='ml-auto fs-5'>
              {/*
              <Nav.Link as={Link} to="/dashboard" className="nav-link ">
                Dashboard
              </Nav.Link>
        */}
              <Nav.Link as={Link} to='/advert'>
                Property Advertisement
              </Nav.Link>
              {/*
              <NavDropdown title="For Landlords" id="basic-nav-dropdown">
                <NavDropdown.Item href="about.html">About Us</NavDropdown.Item>
                <NavDropdown.Item href="features.html">
                  Features & Addons
                </NavDropdown.Item>
                <NavDropdown.Item href="pricing.html">
                  Pricing Packages
                </NavDropdown.Item>
                <NavDropdown.Item href="download.html">
                  Download Page
                </NavDropdown.Item>
                <NavDropdown.Item href="projects.html">
                  Our Projects
                </NavDropdown.Item>
                <NavDropdown.Item href="project-details.html">
                  Project Details
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="For Tenants" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/tenantapplication">
                  Rental Application Form
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to="/blogs">
                Blogs
              </Nav.Link>
              */}
              {/*
              <Nav.Link as={Link} to="/contactus">
                Contact Us
              </Nav.Link>
            */}
            </Nav>
          </Navbar.Collapse>
        )}

        {getCurrentUser() && (
          <>
            <Navbar.Collapse
              id='responsive-navbar-nav'
              style={{ justifyContent: 'center' }}
            >
              <Nav className='ml-auto fs-5'>
                <Nav.Link as={Link} to='/dashboard' className='nav-link '>
                  Dashboard
                </Nav.Link>

{/*                <Nav.Link
                  as={Link}
                  to='/primaryApplicationForm'
                  className='nav-link '
                >
                  Primary Application Form
                </Nav.Link>
                
               
               
                <Nav.Link as={Link} to="/myproperties">
                  My Properties
                </Nav.Link>

                <NavDropdown title="For Landlords" id="basic-nav-dropdown">
                  <NavDropdown.Item href="about.html">
                    About Us
                  </NavDropdown.Item>
                  <NavDropdown.Item href="features.html">
                    Features & Addons
                  </NavDropdown.Item>
                  <NavDropdown.Item href="pricing.html">
                    Pricing Packages
                  </NavDropdown.Item>
                  <NavDropdown.Item href="download.html">
                    Download Page
                  </NavDropdown.Item>
                  <NavDropdown.Item href="projects.html">
                    Our Projects
                  </NavDropdown.Item>
                  <NavDropdown.Item href="project-details.html">
                    Project Details
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="For Tenants" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/tenantapplication">
                    Rental Application Form
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link as={Link} to="/blogs">
                  Blogs
                </Nav.Link>
        */}
                {/*
                <Nav.Link as={Link} to="/contactus">
                  Contact Us
                </Nav.Link>
      */}
              </Nav>
            </Navbar.Collapse>

            <Navbar.Collapse
              style={{
                justifyContent: 'right',
                paddingRight: '100px',
              }}
            >
              <Nav
                style={{
                  paddingRight: '2%',
                }}
                className='fs-5'
              >
                <div className='ms-1 header-item d-none d-sm-flex'>
                  <button
                    type='button'
                    className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
                    data-toggle='fullscreen'
                    onClick={toggleFullscreen}
                  >
                    <i className='bx bx-fullscreen fs-22'></i>
                  </button>
                </div>

                <div className='ms-1 header-item d-none d-sm-flex'>
                  <button
                    type='button'
                    className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode'
                    onClick={toggleDarkMode}
                  >
                    <i
                      className={`bx ${
                        isDarkMode ? 'bx-sun' : 'bx-moon'
                      } fs-22`}
                    ></i>
                  </button>
                </div>
                <div
                  className='dropdown topbar-head-dropdown ms-1 header-item'
                  id='notificationDropdown'
                >
                  <button
                    type='button'
                    className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
                    id='page-header-notifications-dropdown'
                    data-bs-toggle='dropdown'
                    data-bs-auto-close='outside'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i className='bx bx-bell fs-22'></i>
                    <span className='position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger visually-hidden'>
                      3<span className='visually-hidden'>unread messages</span>
                    </span>
                  </button>
                </div>
              </Nav>

              <Nav className='fs-5'>
                <img
                  className='rounded-circle header-profile-user'
                  src={profileImg}
                  alt='Header Avatar'
                />

                <NavDropdown
                  title={
                    tenantProfile?.firstName && tenantProfile?.lastName
                      ? `${tenantProfile.firstName} ${tenantProfile.lastName}`
                      : 'Unknown Tenant'
                  }
                  id='basic-nav-dropdown'
                >
                  {/*
                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item "
                  >
                    <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Profile</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                  >
                    <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Messages</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                  >
                    <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Taskboard</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                  >
                    <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Help</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                  >
                    <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">
                      Balance : <b>$5971.67</b>
                    </span>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                  >
                    <span className="badge bg-success-subtle text-success mt-1 float-end">
                      New
                    </span>
                    <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Settings</span>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="features.html"
                    className="dropdown-item"
                  >
                    <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Lock screen</span>
                  </NavDropdown.Item>
                */}
                  <NavDropdown.Item
                    className='dropdown-item'
                    exact
                    href='/logout'
                    onClick={() => {
                      //clear profile
                      tenantProfile = {};
                    }}
                  >
                    <i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>{' '}
                    <span className='align-middle' data-key='t-logout'>
                      Logout
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    </React.Fragment>
  );
};

export default NavigationBar;

{
  /*

 <NavDropdown.Item href="about.html">About Us</NavDropdown.Item>
              <NavDropdown.Item href="features.html">
                Features & Addons
              </NavDropdown.Item>
              <NavDropdown.Item href="pricing.html">
                Pricing Packages
              </NavDropdown.Item>
              <NavDropdown.Item href="download.html">
                Download Page
              </NavDropdown.Item>
              <NavDropdown.Item href="projects.html">
                Our Projects
              </NavDropdown.Item>
              <NavDropdown.Item href="project-details.html">
                Project Details
              </NavDropdown.Item>

			  

	{/*
    <div>
      <div classNameName={click ? "main-container" : ""} onClick={() => Close()} />
      <nav classNameName="navbar" onClick={(e) => e.stopPropagation()}>
        <div classNameName="nav-container">
          <NavLink exact to="/" classNameName="nav-logo">
            Gurenter
            <i classNameName="fa fa-code"></i>
          </NavLink>
          <ul classNameName={click ? "nav-menu active" : "nav-menu"}>
            {localStorage.getItem(config.token_key) && (
              <React.Fragment>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/dashboard"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/myproperties"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    My Properties
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/blog"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Blog
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/contact"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/logout"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Sign Out
                  </NavLink>
                </li>
              </React.Fragment>
            )}

            {!localStorage.getItem(config.token_key) && (
              <React.Fragment>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/signup"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Sign Up
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Log In
                  </NavLink>
                </li>
              </React.Fragment>
            )}
         
          </ul>
          <div classNameName="nav-icon" onClick={handleClick}>
            <i classNameName={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
        {localStorage.getItem(config.token_key) && (
          <div className="profile-nav-card main-header-profile bg-secondary p-1 main-header-right">
            <div className="d-flex wd-100p">
              <div className="my-auto" styles={{ textAlign: "center !Important" }}>
                <h6 styles={{ fontSize: 12 }}>
                  <b>
                    {tenantProfile.firstName} {tenantProfile.lastName}
                  </b>
                </h6>
                <span>Landlord</span>
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>

		*/
}

{
  /*
    <div>
      <div classNameName={click ? "main-container" : ""} onClick={() => Close()} />
      <nav classNameName="navbar" onClick={(e) => e.stopPropagation()}>
        <div classNameName="nav-container">
          <NavLink exact to="/" classNameName="nav-logo">
            Gurenter
            <i classNameName="fa fa-code"></i>
          </NavLink>
          <ul classNameName={click ? "nav-menu active" : "nav-menu"}>
            {localStorage.getItem(config.token_key) && (
              <React.Fragment>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/dashboard"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/myproperties"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    My Properties
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/blog"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Blog
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/contact"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/logout"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Sign Out
                  </NavLink>
                </li>
              </React.Fragment>
            )}

            {!localStorage.getItem(config.token_key) && (
              <React.Fragment>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/signup"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Sign Up
                  </NavLink>
                </li>
                <li classNameName="nav-item">
                  <NavLink
                    exact
                    to="/"
                    activeclassNameName="active"
                    classNameName="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Log In
                  </NavLink>
                </li>
              </React.Fragment>
            )}
         
          </ul>
          <div classNameName="nav-icon" onClick={handleClick}>
            <i classNameName={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
        {localStorage.getItem(config.token_key) && (
          <div className="profile-nav-card main-header-profile bg-secondary p-1 main-header-right">
            <div className="d-flex wd-100p">
              <div className="my-auto" styles={{ textAlign: "center !Important" }}>
                <h6 styles={{ fontSize: 12 }}>
                  <b>
                    {tenantProfile.firstName} {tenantProfile.lastName}
                  </b>
                </h6>
                <span>Landlord</span>
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>

		*/
}
