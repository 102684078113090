import React from 'react';

const SelectInputLabel = ({
  name,
  label,
  className = 'form-control',
  labelClass,
  isRequired,
  iconClassName,
  value,
  onChange,
  error,
  children, // add children prop for options
  disabled = false,
}) => {
  return (
    <div className='input-box'>
      <label className={labelClass}>
        {label}
        {isRequired && <span className='primary-color-2 ml-1'> * </span>}
      </label>
      <div className='form-group'>
        <select
          className={className}
          name={name}
          id={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
        >
          {children} // render options
        </select>
        <span className={iconClassName} style={{ color: 'green' }}></span>
      </div>

      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  );
};

export default SelectInputLabel;
