import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { fetchPropertyAdvertById } from '../../../../services/property/advertisement/propertyAdvertisement';
import { Button } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap'; // or whichever tooltip component you're using
//add analytics checks here

const PropertyUnitAdvert = ({ isDarkMode }) => {
  const [isLoading, setIsLoading] = useState();
  const { campaignId } = useParams();
  const [propertyUnitAdvert, setPropertyUnitAdvert] = useState({
    advertId: '',
    landlordId: '',
    propertyUnitId: '',
    dateAdvertEnds: '',
    campaignId: '',
    propertyUnit: {
      propertyUnitId: '',
      rentalPropertyId: '',
      unitName: '',
      rentReceiptSequence: 0,
      rentalUnitPrice: 0,
      rentalUnitAmtCurrency: 'USD',
      unitType: '',
      unitCurrentRentalStatus: 'Vacant',
      unitSpecifications: {
        numberOfBedrooms: 0,
        unitSquareFootage: 0,
        parkingDetail: {
          drivewayType: '', //paved, gravel, dirt
          parkingSpace: 0,
        },
        numberOfRooms: 0,
        numberOfBathrooms: 0,
        numberOfHalfBathroom: 0,

        hasKitchen: false,
        hasPatios: false,
        hasPorch: false,
        hasDiningRoom: false,
        hasHalfBathrooms: false,
        hasLivingRoom: false,
        hasCarGarage: false,
      },
      petSpecifications: {
        allowPets: false,
        maxNumberOfPetsAllowed: 0,
        petsAllowed: [
          {
            animalName: '',
            animalType: '',
            animalWeightMax: 0,
          },
        ],
      },
      dateLastRented: '',
      recordCreatedDate: '',
      isRented: false,
      isActivated: true,
    },
    unitAdvertImageUrls: [''],
    securityDeposit: 0,
    currencyId: '',
    currencyName: '',
    isAdvertExpired: false,
  });

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    getAdvertDetail();
    //after loading

    setIsLoading(false);
  }, []);

  const getAdvertDetail = async () => {
    const { data: propertyUnitAdvert } = await fetchPropertyAdvertById(
      campaignId
    );

    console.log(propertyUnitAdvert);
    setPropertyUnitAdvert(propertyUnitAdvert);
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && (
        <div id='global-loader'>
          {/*	<img src={loader} className='loader-img' alt='Loader' /> */}
        </div>
      )}
      {/*/Loader */}
      {!campaignId ? (
        <div
          className='page-content'
          data-bs-theme={isDarkMode ? 'dark' : 'light'}
          style={{ paddingBottom: '10%' }}
        >
          <div class='row'>
            <div class='col-xl-8' id='card-slide'>
              <div className='alert alert-warning' role='alert'>
                <h4 className='alert-heading'>Missing Campaign ID</h4>
                <p>
                  A Campaign ID is required in the URL to present an active
                  property unit for rent. Please ensure a valid Campaign ID is
                  provided.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className='page-content'
          data-bs-theme={isDarkMode ? 'dark' : 'light'}
          style={{ paddingBottom: '10%' }}
        >
          <div class='row'>
            <div class='col-xl-8' id='card-slide'>
              {/*Carousel */}
              <Carousel data-bs-theme='dark'>
                {propertyUnitAdvert &&
                  propertyUnitAdvert.unitAdvertImageUrls.map(
                    (imageUrl, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className='d-block w-100'
                          src={imageUrl}
                          alt={`Slide ${index + 1}`}
                          style={{
                            minHeight: '150px',
                            maxHeight: '400px',
                            objectFit: 'cover',
                          }}
                        />
                        <Carousel.Caption>
                          <h5>{`Slide ${index + 1}`}</h5>
                        </Carousel.Caption>
                      </Carousel.Item>
                    )
                  )}
              </Carousel>
            </div>
            <div class='col-xl-4' id='card-none1'>
              <div class='card'>
                <div class='card-header'>
                  <div class='d-flex align-items-center'>
                    <div class='flex-grow-1'>
                      <h6 class='card-title mb-0'>
                        {propertyUnitAdvert.propertyUnit && (
                          <b>
                            $ {propertyUnitAdvert.propertyUnit.rentalUnitPrice}{' '}
                            {propertyUnitAdvert.currencyId} {'/'}
                            {' month'}
                          </b>
                        )}
                      </h6>
                    </div>
                    <div class='flex-shrink-0'></div>
                  </div>
                </div>
                <div class='card-body collapse show' id='collapseexample1'>
                  <div class='d-flex'>
                    <div class='flex-shrink-0'>
                      <i class='ri-checkbox-circle-fill text-primary'></i>
                    </div>
                    <div class='flex-grow-1 ms-2 text-muted'>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Available Until</p>
                        <h5 class='card-title mb-1'>
                          {new Date(
                            propertyUnitAdvert.dateAdvertEnds
                          ).toLocaleString('default', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          })}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='d-flex mt-2 col-6'>
                      <div class='flex-shrink-0'>
                        <i class='ri-checkbox-circle-fill text-primary'></i>
                      </div>
                      <div class='flex-grow-1 ms-2 text-muted'>
                        <div class='flex-grow-1 ms-2'>
                          <p class='text-muted mb-0'>Security Deposit</p>
                          <h5 class='card-title mb-1'>
                            $ {propertyUnitAdvert.securityDeposit}{' '}
                            {propertyUnitAdvert.currencyId}
                          </h5>
                        </div>
                      </div>
                    </div>

                    {/* Type*/}
                    <div class='d-flex mt-2 col-6'>
                      <div class='flex-shrink-0'>
                        <i class='ri-checkbox-circle-fill text-primary'></i>
                      </div>
                      <div class='flex-grow-1 ms-2 text-muted'>
                        <div class='flex-grow-1 ms-2'>
                          <p class='text-muted mb-0'>Unit Type</p>
                          {propertyUnitAdvert.propertyUnit && (
                            <h5 class='card-title mb-1'>
                              {propertyUnitAdvert.propertyUnit.unitType
                                .toUpperCase()
                                .charAt(0) +
                                propertyUnitAdvert.propertyUnit.unitType
                                  .slice(1)
                                  .toLowerCase()}
                            </h5>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Square Foot and things usefull */}
                  {propertyUnitAdvert.propertyUnit.unitSpecifications && (
                    <div class='row'>
                      <div class='d-flex mt-2 col-6'>
                        <div class='flex-shrink-0'>
                          <i class='ri-checkbox-circle-fill text-primary'></i>
                        </div>
                        <div class='flex-grow-1 ms-2 text-muted'>
                          <div class='flex-grow-1 ms-2'>
                            <p class='text-muted mb-0'>
                              Interior Square Footage
                            </p>
                            <h5 class='card-title mb-1'>
                              {
                                propertyUnitAdvert.propertyUnit
                                  .unitSpecifications?.unitSquareFootage
                              }{' '}
                              sqft
                            </h5>
                          </div>
                        </div>
                      </div>

                      {/* Type*/}
                      {propertyUnitAdvert.propertyUnit.unitSpecifications
                        .parkingDetail && (
                        <div class='d-flex mt-2 col-6'>
                          <div class='flex-shrink-0'>
                            <i class='ri-checkbox-circle-fill text-primary'></i>
                          </div>
                          <div class='flex-grow-1 ms-2 text-muted'>
                            <div class='flex-grow-1 ms-2'>
                              <p class='text-muted mb-0'>Parking Type</p>
                              <h5 class='card-title mb-1'>
                                {propertyUnitAdvert.propertyUnit.unitSpecifications.parkingDetail.drivewayType
                                  .toUpperCase()
                                  .charAt(0) +
                                  propertyUnitAdvert.propertyUnit.unitSpecifications.parkingDetail.drivewayType
                                    .slice(1)
                                    .toLowerCase()}
                              </h5>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div class='d-flex mt-4'>
                    {propertyUnitAdvert.isAdvertExpired ? (
                      <button
                        className='btn btn-success w-lg waves-effect waves-light'
                        disabled
                      >
                        Sign Up
                      </button>
                    ) : (
                      <Link
                        to={`/rentalSignUp/${propertyUnitAdvert.campaignId}`}
                        className='btn btn-success w-lg waves-effect waves-light'
                      >
                        Sign Up
                      </Link>
                    )}
                  </div>
                  <div class='d-flex mt-4'>
                    {propertyUnitAdvert.isAdvertExpired ? (
                      <h4 style={{ color: 'red' }}>
                        We are no longer accepting applicants
                      </h4>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/*end col */}
          </div>
          {/*end row */}

          {/*Unit Name */}
          <div class='row'>
            <div class='col-xl-8'>
              <div class='card'>
                <div class='card-body'>
                  <div class='d-flex position-relative'>
                    <div>
                      <Button
                        hidden
                        size='sm'
                        href='javascript:void(0);'
                        class='btn btn-primary stretched-link'
                      >
                        Share
                      </Button>

                      <h5 class='mt-0' style={{ paddingTop: '1%' }}>
                        {propertyUnitAdvert?.propertyUnit?.unitName
                          ?.toUpperCase()
                          .charAt(0) +
                          propertyUnitAdvert?.propertyUnit?.unitName?.slice(1)}
                      </h5>
                      <p>
                        The location of the property{' '}
                        <b>
                          {' '}
                          {propertyUnitAdvert?.propertyUnit?.unitName?.toUpperCase()}
                        </b>
                        . Will be provided only if you have been approved to
                        view the property. This will be done by a initial
                        screening process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end col */}
          </div>
          {/*end row */}

          {/* Unit Specifications and Amenities*/}

          <div class='row'>
            <div class='col-12'>
              <div class='justify-content-between d-flex align-items-center mt-3 mb-4'>
                <h5 class='mb-0 pb-1 text-decoration-underline'>
                  {' '}
                  {propertyUnitAdvert?.propertyUnit?.unitName
                    ?.toUpperCase()
                    .charAt(0) +
                    propertyUnitAdvert?.propertyUnit?.unitName?.slice(1)}{' '}
                  {"'s "}
                  Amenities
                </h5>
              </div>
              <div class='row row-cols-xxl-5 row-cols-lg-3 row-cols-1'>
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-ruler-combined la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Square Footage</p>
                        <h5 class='card-title mb-1'>
                          {
                            propertyUnitAdvert?.propertyUnit?.unitSpecifications
                              ?.unitSquareFootage
                          }{' '}
                          sqft
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-bed la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Bedrooms</p>
                        <h5 class='card-title mb-1'>
                          {
                            propertyUnitAdvert?.propertyUnit?.unitSpecifications
                              ?.numberOfBedrooms
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-bath la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Bathrooms</p>
                        <h5 class='card-title mb-1'>
                          {
                            propertyUnitAdvert?.propertyUnit?.unitSpecifications
                              ?.numberOfBathrooms
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-toilet la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Half Bathrooms</p>
                        <h5 class='card-title mb-1'>
                          {' '}
                          {
                            propertyUnitAdvert?.propertyUnit?.unitSpecifications
                              ?.numberOfHalfBathroom
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-door-open la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Rooms</p>
                        <h5 class='card-title mb-1'>
                          {
                            propertyUnitAdvert?.propertyUnit?.unitSpecifications
                              ?.numberOfRooms
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
              </div>

              {/* Optional Amenities Section */}

              <div class='row row-cols-xxl-5 row-cols-lg-3 row-cols-1'>
                <div class='col'>
                  <div class='card card-body'>
                    <div class='d-flex mb-2 align-items-center'>
                      <div class='flex-shrink-0'>
                        <i class='las la-parking la-3x'></i>
                      </div>
                      <div class='flex-grow-1 ms-2'>
                        <p class='text-muted mb-0'>Parking Spaces</p>
                        <h5 class='card-title mb-1'>
                          {
                            propertyUnitAdvert?.propertyUnit?.unitSpecifications
                              ?.parkingDetail?.parkingSpace
                          }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end col */}
                {propertyUnitAdvert?.propertyUnit?.unitSpecifications
                  ?.hasKitchen && (
                  <div class='col'>
                    <div class='card card-body'>
                      <div class='d-flex mb-2 align-items-center'>
                        <div class='flex-shrink-0'>
                          <i class='las la-blender la-3x'></i>
                        </div>
                        <div class='flex-grow-1 ms-2'>
                          <h5 class='card-title mb-1'>Kitchen</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* end col */}
                {propertyUnitAdvert?.propertyUnit?.unitSpecifications
                  ?.hasLivingRoom && (
                  <div class='col'>
                    <div class='card card-body'>
                      <div class='d-flex mb-2 align-items-center'>
                        <div class='flex-shrink-0'>
                          <i class='las la-couch la-3x'></i>
                        </div>
                        <div class='flex-grow-1 ms-2'>
                          <h5 class='card-title mb-1'>Living Room</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* end col */}

                {propertyUnitAdvert?.propertyUnit?.unitSpecifications
                  ?.hasCarGarage && (
                  <div class='col'>
                    <div class='card card-body'>
                      <div class='d-flex mb-2 align-items-center'>
                        <div class='flex-shrink-0'>
                          <i class='las la-car la-3x'></i>
                        </div>
                        <div class='flex-grow-1 ms-2'>
                          <h5 class='card-title mb-1'>Car Garage</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* end col */}
                {propertyUnitAdvert?.propertyUnit?.unitSpecifications
                  .hasPatios && (
                  <div class='col'>
                    <div class='card card-body'>
                      <div class='d-flex mb-2 align-items-center'>
                        <div class='flex-shrink-0'>
                          <i class='las la-pallet la-3x'></i>
                        </div>
                        <div class='flex-grow-1 ms-2'>
                          <h5 class='card-title mb-1'>Patio</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* end col */}

                {propertyUnitAdvert?.propertyUnit?.unitSpecifications
                  .hasPorch && (
                  <div class='col'>
                    <div class='card card-body'>
                      <div class='d-flex mb-2 align-items-center'>
                        <div class='flex-shrink-0'>
                          <i class='las la-home la-3x'></i>
                        </div>
                        <div class='flex-grow-1 ms-2'>
                          <h5 class='card-title mb-1'>Porch</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* end col */}
                {propertyUnitAdvert?.propertyUnit?.unitSpecifications
                  ?.hasDiningRoom && (
                  <div class='col'>
                    <div class='card card-body'>
                      <div class='d-flex mb-2 align-items-center'>
                        <div class='flex-shrink-0'>
                          <i class='las la-table la-3x'></i>
                        </div>
                        <div class='flex-grow-1 ms-2'>
                          <h5 class='card-title mb-1'>Dining Room</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* end row */}
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
      )}
      {/* Optional Amenities Section */}
    </React.Fragment>
  );
};

export default PropertyUnitAdvert;
