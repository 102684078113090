import React from "react";

function PasswordInput({
  name,
  label,
  value,
  error,
  onChange,
  showPassword,
  setShowPassword,
  disabled = false,
}) {
  return (
    <div>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div className="form-icon right" style={{ position: "relative" }}>
        <input
          type={showPassword ? "text" : "password"}
          className="form-control form-control-icon"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          autoComplete="new-password"
          placeholder={`Enter ${label}`}
          disabled={disabled}
        />
        <i
          onClick={() => setShowPassword(!showPassword)}
          className={showPassword ? "mdi mdi-eye-off-outline" : "mdi mdi-eye"}
          style={{
            position: "absolute",
            right: 10,
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            height: "20px",
            width: "20px",
          }}
        ></i>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

export default PasswordInput;
