import React, { useEffect, useState, useReducer } from 'react';
import { useHistory, useNavigate } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';
import Joi from 'joi-browser';
import CryptoJS from 'crypto-js';
import { Bars } from 'react-loader-spinner';
import Input from '../../reusable/form/Input';
import config from '../../../constants/config.json';
import { toast } from 'react-toastify';
import TextInputLabel from '../../reusable/form/textinputlabel';
import CheckboxInput from '../../reusable/form/checkboxInput';
import { fetchPropertyAdvertById } from '../../../services/property/advertisement/propertyAdvertisement';
import {
  createTenantPreScreening,
  getCurrentUserProfileByEmail,
} from '../../../services/pre-screening/prescreeningManagementService';
import PasswordInput from '../../reusable/form/passwordInput';
import CurrentAddressAppliForm from './sections/currentAddressAppliForm';
import PreviousAddressAppliForm from './sections/previousAddressAppliForm';
import OtherOccupantAppliForm from './sections/otherApplicantAppliForm';
import EmploymentAndIncomeForm from './sections/employmentAndIncomeForm';
import {
  decryptData,
  generateKey,
  updateTenantProfileMainAppli,
} from '../../../services/tenant/masterApplication/tenantMainApplicationManagement';
const { v4: uuidv4 } = require('uuid');

//TODO: need to figure out how to populate the datepicker
//disable any fields pre-filled from the prescreening
//stepper for the future
//https://blog.logrocket.com/add-stepper-components-react-app/

//Need to be able to upload documents, select what category they are, by giving them a name, eg. Job Letter

const PrimaryApplicationForm = ({ isDarkMode }) => {
  //icons https://pictogrammers.github.io/@mdi/font/2.0.46/
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [showSsnTrn, setshowSsnTrn] = useState(false);
  const [showDriversLicenseNumber, setshowDriversLicenseNumber] =
    useState(false);
  const { campaignId } = useParams();

  const navigate = useNavigate();
  const [tenantProfile, setTenantProfile] = useState({
    tenantId: '',
    isTheMainTenant: false,
    hasCompletedApplicationSignUp: false,
    firstName: '',
    middleName: '',
    lastName: '',
    workPhoneNumber: '',
    dateOfBirth: new Date(),
    mainTenantImageUrl: '',
    emailAddress: '',
    //password: "",
    signatureDetail: [],
    currentApplPool: {
      landLordId: '',
      propertyId: '',
      contractId: '',
      inviteAccessCode: '',
      hasAccessCodeBeenUsed: false,
      isApprovedOrRejected: false,
      finalDecision: '',
      applicationStatus: 'Not Started',
    },
    tenantApplication: {
      latestVersion: false,
      applicationLastUpdate: null,
      propertyId: '',
      propertyName: '',
      tenantApplicationSummaryData: {
        numberofOccupants: 0,
        numberOfReferenceAdded: 0,
        combinedSalary: 0,
      },
      rentCost: [],
      startDate: '',
      referredByDetail: [],
      applicantInfo: {
        firstName: '',
        middleInitial: '',
        lastName: '',
        trnSsn: '',
        driverLicense: '',
        dateOfBirth: '',
        homePhone: '',
        workPhone: '',
        emailAddress: '',
      },
      //separate the address into its own collection
      currentAddressDetail: {
        addressType: '',
        streetAddress: '',
        cityTown: '',
        parishState: '',
        countryName: '',
        zipPostalCode: '',
        dateIn: '',
        landLordName: '',
        landLordPhone: '',
        monthlyRent: '',
        recordTimeStamp: null,
      },
      previousAddress: {
        addressType: '',
        streetAddress: '',
        cityTown: '',
        parishState: '',
        countryName: '',
        zipPostalCode: '',
        dateIn: '',
        landLordName: '',
        landLordPhone: '',
        monthlyRent: '',
        recordTimeStamp: null,
      },
      preScreeningDetail: {
        emailAddress: '',
        firstName: '',
        lastName: '',
        durationOfCurrentResidence: 0,
        totalMonthlyIncome: 0,
        sourceOfIncome: '',
        numberOfOccupants: 0,
        reasonForLeaving: '',
        desiredMoveInDate: new Date(),
        doesAnyOneSmoke: false,
        hasPets: false,
        describePets: '',
      },
      //make it into its own collection

      otherOccupants: [
        {
          firstName: '',
          lastName: '',
          birthYear: '',
          relationship: '',
          isOver18: false,
          emailAddress: '',
        },
      ],
      petsDetail: [],
      employmentIncomeInfo: [
        {
          occupationName: '',
          employerCompany: '',
          monthlySalary: {
            salaryAmt: 0,
            salaryAmtCents: 0,
            currencyName: '',
          },
          supervisorName: '',
          supervisorPhone: '',
          startDate: '',
          endDate: '',
        },
      ],
      otherIncomeSources: [], //not necessary
      //NEXT
      emergencyContact: [{}],
      personalReferences: [],
      backgroundInfo: [],
      vehicleInfo: [],
    },
    recordCreatedDate: null,
    isPublic: false,
  });

  //prescreening details
  /*
  const [data, setData] = useState({
    emailAddress: "",

    firstName: "",
    middleName: "",
    lastName: "",
    durationOfCurrentResidence: 0,
    totalMonthlyIncome: 0,
    sourceOfIncome: "",
    numberOfOccupants: 0,
    reasonForLeaving: "",
    desiredMoveInDate: new Date(),
    doesAnyOneSmoke: false,
    hasPets: false,
    describePets: "",

    ssnTrn: "", //should be encrypted
    driversLicenseNumber: "", //should be encrypted
    dateOfBirth: new Date(),
    cellPhoneNumber: "",
    workPhoneNumber: "",
  });
*/
  const [application, setApplication] = useState({});

  const [errors, setErrors] = useState({
    tenantId: null,
    isTheMainTenant: null,
    hasCompletedApplicationSignUp: null,
    firstName: null,
    middleName: null,
    lastName: null,
    workPhoneNumber: null,
    dateOfBirth: null,
    mainTenantImageUrl: null,
    emailAddress: null,
    signatureDetail: null,
    currentApplPool: {
      landLordId: null,
      propertyId: null,
      contractId: null,
      inviteAccessCode: null,
      hasAccessCodeBeenUsed: null,
      isApprovedOrRejected: null,
      finalDecision: null,
      applicationStatus: null,
    },
    tenantApplication: {
      latestVersion: null,
      applicationLastUpdate: null,
      propertyId: null,
      propertyName: null,
      tenantApplicationSummaryData: {
        numberofOccupants: null,
        numberOfReferenceAdded: null,
        combinedSalary: null,
      },
      rentCost: null,
      startDate: null,
      referredByDetail: null,
      applicantInfo: {
        firstName: null,
        middleInitial: null,
        lastName: null,
        trnSsn: null,
        driverLicense: null,
        dateOfBirth: null,
        homePhone: null,
        workPhone: null,
        emailAddress: null,
      },
      //Make this intoits own collection so its easier to edit and add parts of the application
      //all addresses are the same just the type is different
      currentAddressDetail: {
        addressType: null,
        streetAddress: null,
        cityTown: null,
        parishState: null,
        countryName: null,
        zipPostalCode: null,
        dateIn: null,
        landLordName: null,
        landLordPhone: null,
        monthlyRent: null,
        recordTimeStamp: null,
      },
      previousAddress: {
        addressType: null,
        streetAddress: null,
        cityTown: null,
        parishState: null,
        countryName: null,
        zipPostalCode: null,
        dateIn: null,
        landLordName: null,
        landLordPhone: null,
        monthlyRent: null,
        recordTimeStamp: null,
      },
      otherOccupants: null,
      petsDetail: null,
      employmentIncomeInfo: null,
      otherIncomeSources: null,
      emergencyContact: null,
      personalReferences: null,
      backgroundInfo: null,
      vehicleInfo: null,
    },
    recordCreatedDate: null,
    isPublic: null,
  });

  const vehicleInfoSchema = [
    {
      make: Joi.string().allow(''),
      model: Joi.string().allow(''),
      year: Joi.number().optional(),
      color: Joi.string().allow(''),
      licensePlate: Joi.string().allow(''),
    },
  ];

  const convictedOfCrimeSchema = [
    {
      hasBeenConvicted: Joi.boolean(),
      typeOfOffence: Joi.string().allow(''),
      countryName: Joi.string().allow(''),
      stateParish: Joi.string().allow(''),
      city: Joi.string().allow(''),
      zipCodePostalCode: Joi.string().allow(''),
      dateConvicted: Joi.string().allow(''),
    },
  ];

  const evictedDetailsSchema = [
    {
      hasBeenEvicted: Joi.boolean(),
      reasonForEviction: Joi.string().allow(''),
      propertyName: Joi.string().allow(''),
      city: Joi.string().allow(''),
      stateParish: Joi.string().allow(''),
      zipCodePostalCode: Joi.string().allow(''),
      landlordName: Joi.string().allow(''),
    },
  ];

  const backgroundInfoSchema = {
    filedForBankruptcy: Joi.boolean(),
    refusedToPayRent: Joi.boolean(),
    evictedDetails: evictedDetailsSchema,
    convictedOfCrime: convictedOfCrimeSchema,
  };

  const personalReferencesSchema = [
    {
      referenceName: Joi.string().allow(''),
      address: Joi.string().allow(''),
      phoneNumber: Joi.string().allow(''),
      contactRelationship: Joi.string().allow(''),
    },
  ];

  const emergencyContactSchema = [
    {
      contactName: Joi.string().allow(''),
      address: Joi.string().allow(''),
      phoneNumber: Joi.string().allow(''),
      contactRelationship: Joi.string().allow(''),
    },
  ];

  const monthlyIncomeSchema = {
    incomeAmt: Joi.number().optional(),
    incomeAmtCents: Joi.number().optional(),
    currencyName: Joi.string().allow(''),
  };

  const otherIncomeSourcesSchema = [
    {
      otherIncomeDescription: Joi.string().allow(''),
      monthlyIncome: monthlyIncomeSchema,
    },
  ];

  const monthlySalarySchema = {
    salaryAmt: Joi.number().optional(),
    salaryAmtCents: Joi.number().optional(),
    currencyName: Joi.string().allow(''),
  };

  const employmentIncomeInfoSchema = [
    {
      occupationName: Joi.string().allow(''),
      employerCompany: Joi.string().allow(''),
      monthlySalary: monthlySalarySchema,
      supervisorName: Joi.string().allow(''),
      supervisorPhone: Joi.string().allow(''),
      startDate: Joi.string().allow(''),
      endDate: Joi.string().allow(''),
    },
  ];

  const petsDetailSchema = [
    {
      petType: Joi.string().allow(''),
      describePet: Joi.string().allow(''),
    },
  ];
  const rentCostSchema = [
    {
      rentAmt: Joi.number().optional(),
      rentAmtCents: Joi.number().optional(),
      currencyName: Joi.string().allow(''),
    },
  ];

  const otherOccupantsSchema = [
    {
      firstName: Joi.string().allow(''),
      lastName: Joi.string().allow(''),
      birthYear: Joi.number().optional(),
      relationship: Joi.string().allow(''),
      isOver18: Joi.boolean(),
      emailAddress: Joi.string().allow(''),
    },
  ];

  const currentApplPoolSchema = {
    landLordId: Joi.string().allow(''),
    propertyId: Joi.string().allow(''),
    contractId: Joi.string().allow(''),
    inviteAccessCode: Joi.string().allow(''),
    hasAccessCodeBeenUsed: Joi.boolean(),
    isApprovedOrRejected: Joi.boolean(),
    finalDecision: Joi.string().allow(''),
    applicationStatus: Joi.string().valid(
      'Not Started',
      'In Progress',
      'Completed'
    ),
  };

  const referredByDetailSchema = [
    {
      agentName: Joi.string().allow(''),
    },
  ];

  const tenantApplicationSummaryDataSchema = {
    numberofOccupants: Joi.number(),
    numberOfReferenceAdded: Joi.number(),
    combinedSalary: Joi.number(),
  };

  const applicantInfoSchema = {
    firstName: Joi.string().allow(''),
    middleInitial: Joi.string().allow(''),
    lastName: Joi.string().allow(''),
    trnSsn: Joi.string().allow(''),
    driverLicense: Joi.string().allow(''),
    dateOfBirth: Joi.string().allow(''),
    homePhone: Joi.string().allow(''),
    workPhone: Joi.string().allow(''),
    emailAddress: Joi.string().email().allow(''),
  };

  //separate forms so its easier to manage
  const currentAddressDetailSchema = {
    addressType: Joi.string().allow(''),
    streetAddress: Joi.string().required().min(5),
    cityTown: Joi.string().allow(''),
    parishState: Joi.string().allow(''),
    countryName: Joi.string().allow(''),
    zipPostalCode: Joi.string().allow(''),
    dateIn: Joi.string().allow(''),
    landLordName: Joi.string().allow(''),
    landLordPhone: Joi.string().allow(''),
    monthlyRent: Joi.string().allow(''),
    recordTimeStamp: Joi.date().allow(null),
  };

  const previousAddressSchema = {
    addressType: Joi.string().allow(''),
    streetAddress: Joi.string().required().min(5),
    cityTown: Joi.string().allow(''),
    parishState: Joi.string().allow(''),
    countryName: Joi.string().allow(''),
    zipPostalCode: Joi.string().allow(''),
    dateIn: Joi.string().allow(''),
    landLordName: Joi.string().allow(''),
    landLordPhone: Joi.string().allow(''),
    monthlyRent: Joi.string().allow(''),
    recordTimeStamp: Joi.date().allow(null),
  };

  const tenantApplicationSchema = {
    latestVersion: Joi.boolean(),
    applicationLastUpdate: Joi.date().allow(null),
    propertyId: Joi.string().allow(''),
    propertyName: Joi.string().allow(''),
    tenantApplicationSummaryData: tenantApplicationSummaryDataSchema,
    rentCost: rentCostSchema,
    startDate: Joi.string().allow(''),
    referredByDetail: referredByDetailSchema,
    applicantInfo: applicantInfoSchema,
    currentAddressDetail: currentAddressDetailSchema,
    previousAddress: previousAddressSchema,
    otherOccupants: otherOccupantsSchema,
    petsDetail: petsDetailSchema,
    employmentIncomeInfo: employmentIncomeInfoSchema,
    otherIncomeSources: otherIncomeSourcesSchema,
    emergencyContact: emergencyContactSchema,
    personalReferences: personalReferencesSchema,
    backgroundInfo: backgroundInfoSchema,
    vehicleInfo: vehicleInfoSchema,
  };

  const signatureDetailSchema = [
    {
      signatureImageUrl: Joi.string().allow(''),
      signatureRecordCreatedDate: Joi.date().allow(null),
    },
  ];

  //main schema, this application form will be broken up into sections and views
  //that all feed back into the main application form
  const schema = {
    tenantId: Joi.string().allow(''),

    isTheMainTenant: Joi.boolean(),
    hasCompletedApplicationSignUp: Joi.boolean(),
    firstName: Joi.string().allow(''),
    middleName: Joi.string().allow(''),
    lastName: Joi.string().allow(''),
    cellPhoneNumber: Joi.string().optional().allow(''),
    workPhoneNumber: Joi.string().required(),
    dateOfBirth: Joi.date(),
    mainTenantImageUrl: Joi.string().allow(''),
    emailAddress: Joi.string().email().allow(''),
    signatureDetail: signatureDetailSchema,
    currentApplPool: currentApplPoolSchema,
    tenantApplication: tenantApplicationSchema,
    recordCreatedDate: Joi.date().allow(null),
    isPublic: Joi.boolean(),
    ssnTrn: Joi.string().optional().allow(''),
  };

  const [isOver18, setIsOver18] = useState([false]);

  const [Loading, setLoading] = useState({
    isLoading: false,
  });

  useEffect(() => {
    setLoading({ isLoading: true });
    window.scrollTo(0, 0);

 toast.warning(
      "Don't use this form, it's not ready yet, the form will be sent as a PDF via email from the landlord, sorry for the inconvenience.",
      {
        autoClose: false,
        closeOnClick: true,
        draggable: false,
      }
    );
    //load data
    getAdvertDetail();

    getTenantProfile();
    //after loading
    setLoading({ isLoading: false });
  }, []);

  const getTenantProfile = async () => {
    //get the tenant profile
    const email = localStorage.getItem(config.email_key);
    console.log(email);
    const { data: tenantProfile } = await getCurrentUserProfileByEmail(email);

    const transportKey = generateKey(tenantProfile);

    if (tenantProfile.ssnTrn) {
      tenantProfile.ssnTrn = decryptData(tenantProfile.ssnTrn, transportKey);
      tenantProfile.driversLicenseNumber = decryptData(
        tenantProfile.driversLicenseNumber,
        transportKey
      );
    }

    if (tenantProfile.dateOfBirth) {
      const decryptedDateOfBirth = await decryptData(
        tenantProfile.dateOfBirth,
        transportKey
      );
      //alert(decryptedDateOfBirth);
      //TODO: not being set
      tenantProfile.dateOfBirth = decryptedDateOfBirth;
    }

    setTenantProfile(tenantProfile);
  };

  //This is not needed until we know where the application is being submitted
  const getAdvertDetail = async () => {
    //get the advert details
    const { data: advertDetail } = await fetchPropertyAdvertById(campaignId);
    console.log(advertDetail);
    //setPropertyUnitAdvert(advertDetail);
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(tenantProfile, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  /*
  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };
  */

  const validateProperty = ({ name, value }) => {
    let obj = {};
    let schemaLocal = {};

    // Use lodash's set function to set the value at path of object
    _.set(obj, name, value);

    // Use lodash's get function to get the value at path of object
    const schemaValue = _.get(schema, name);

    if (schemaValue) {
      _.set(schemaLocal, name, schemaValue);
    } else {
      return `No schema defined for ${name}`;
    }

    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();
    // console.log(errors);
    //show the toasts for the errors
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key];
        toast.error(element);
      }
    }

    //toast.error(errors.password);
    setErrors({ errors: errors || {} });
    if (errors) return;

    console.log(tenantProfile.desiredMoveInDate);

    doSubmit();
  };

  //Change current and previous to work like other occupants
  function handleChange(eventOrValue) {
    let value, type, checked, name;

    // Check if the first argument is an event or a value
    if (eventOrValue && eventOrValue.target) {
      ({ value, type, checked, name } = eventOrValue.target);
    } else {
      ({ value, name } = eventOrValue);
      type = 'date';
    }

    let errorMessage = '';
    let newData = { ...tenantProfile };

    if (type === 'checkbox') {
      newData[name] = checked;
    } else if (name === 'desiredMoveInDate' || name === 'dateOfBirth') {
      newData[name] = value; // Use the date string directly
    } else {
      // Check if it's a nested property
      /*
      if (name.startsWith('tenantApplication.currentAddressDetail.')) {
        const key = name.split('.')[2];
        newData.tenantApplication.currentAddressDetail[key] = value;
      } else
      
      
      if (name.startsWith('tenantApplication.previousAddress.')) {
        const key = name.split('.')[2];
        newData.tenantApplication.previousAddress[key] = value;
      } else {
        newData[name] = value;
      }
      */
      newData[name] = value;

      errorMessage = validateProperty({ name, value });
    }

    // Update the errors state immediately with the new error message
    setErrors((prevState) => ({
      ...prevState,
      [name]: errorMessage || null,
    }));

    setTenantProfile(newData);
  }

  const doSubmit = async () => {
    //login function
    try {
      //TODO: NEXT
      return;
      //also have a version directly in the campaign that already knows the campaignId
      //alert("Need to let the user select the campaign they're applying to");
      /*alert(
        'Add all the data to the application like the prescreening, current address, etc'
      );*/
      //const { data } = this.state;
      //FORMAT TO MATCH THE DETAILS NEEDED FROM THE END POINT
      const prescreeningDetail = {
        emailAddress: tenantProfile.emailAddress,

        firstName: tenantProfile.firstName,
        lastName: tenantProfile.lastName,
        durationOfCurrentResidence: tenantProfile.durationOfCurrentResidence,
        totalMonthlyIncome: tenantProfile.totalMonthlyIncome,
        sourceOfIncome: tenantProfile.sourceOfIncome,
        numberOfOccupants: tenantProfile.numberOfOccupants,
        reasonForLeaving: tenantProfile.reasonForLeaving,
        desiredMoveInDate: tenantProfile.desiredMoveInDate,
        doesAnyOneSmoke: tenantProfile.doesAnyOneSmoke,
        hasPets: tenantProfile.hasPets,
        describePets: tenantProfile.describePets,
      };

      const tenantProfile = {
        tenantId: tenantProfile.tenantId, //uuidv4(), //isnt new
        firstName: tenantProfile.firstName,
        middleName: tenantProfile.middleName,
        lastName: tenantProfile.lastName,
        emailAddress: tenantProfile.emailAddress,
        //password: tenantProfile.password,
        mainTenantImageUrl: '',
        preScreeningDetail: prescreeningDetail,
        ssnTrn: tenantProfile.ssnTrn,
        driversLicenseNumber: tenantProfile.driversLicenseNumber,
        dateOfBirth: tenantProfile.dateOfBirth,
        cellPhoneNumber: tenantProfile.cellPhoneNumber,
      };

      // console.log(`Unit ID: ${propertyUnitAdvert.propertyUnitId}`);
      const prescreenFullApplication = {
        campaignId: campaignId, //set from the campaign info
        // landlordId: propertyUnitAdvert.landlordId, //set from the campaign info
        //   propertyUnitId: propertyUnitAdvert.propertyUnitId, //set from the campaign info
        // tenantProfile: tenantProfile,
        preScreeningDetail: prescreeningDetail,
      };

      console.log(tenantProfile);

      console.log(
        'Store the latest version of the application then submit snapshot to the landlord'
      );

      //TODO: need the endpoint to be created
      const { data: response } = await createTenantPreScreening(
        prescreenFullApplication
      );

      console.log('Response');
      console.log(response);

      //Need to add the above data into
      //tenant and its prescreeningDetails (master), also the
      //tenantRentalApplicationSnapshotScehma (copy)
      //snapshot prescreening cant be adjusted,
      //when the entire snapshot submitted the tenant cant change it
      //full application
      // NEXT;
      //console.log(loginPayLoad);
      //alert("Login called");
      //return;
      //const test = await authLoginLandlord(loginPayLoad);
      //Not working
      // alert(`Login called ${test}`);

      //remove this after testing

      toast.success('Submitted Successfully');

      //force update
      forceUpdate();

      //force a reload of the page
      window.location = '/dashboard';

      navigate('/dashboard');
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.emailAddress = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };

  //TODO: need the ability to save at any time
  const handlePreSave = () => {
    //Store what the user has entered so far
    try {
      //alert('Updating master form');
      console.log('New Data Saving');
      console.log(tenantProfile);
      console.log(tenantProfile.previousAddress);
      //alert(tenantProfile.dateOfBirth);
      // updateTenantProfileMainAppli(tenantProfile)
      const { data: response } = updateTenantProfileMainAppli(tenantProfile);
      console.log('Response');
      console.log(response);
    } catch (ex) {
      toast.error(ex.response.data);
    }

    //save what is currently in the entire form and all sections
    //the submit will do the full validation
    //this only stores to the tenantProfile -> application

    toast.success('Saved Successfully');
  };

  //TODO: need the ability to save at any time

  return (
    <React.Fragment>
      {/*Loader */}
      {Loading.isLoading && (
        <div
          id='global-loader'
          style={{
            zIndex: '99',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'absolute',
          }}
        >
          <Bars
            height='80'
            width='80'
            color='#4fa94d'
            ariaLabel='bars-loading'
            wrapperStyle={{}}
            wrapperclassName=''
            visible={true}
          />
        </div>
      )}
      {/*/Loader */}
      {/* prefil things from the prescreening */}

      <div className='main-content'>
        <div className='page-content'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='card'>
                  <div className='card-header d-flex flex-column'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <h4 className='card-title mb-0'>
                          Primary Application Form
                        </h4>
                        <h6
                          className='card-subtitle text-muted'
                          style={{ paddingTop: '0.5%' }}
                        >
                          This is your copy, when you are submitting an
                          application to a landlord, they will only get a
                          snapshot of this information.
                        </h6>
                      </div>

                      {/* Save Form at anytime located to the right of the form corner*/}
                      {/*No validation since its saving what we currently have, when submit you have
                        to select the campaign (landlord your sending it to from your list of campaigns that are active) */}
                      <div>
                        <button
                          className='btn btn-primary'
                          type='submit'
                          onClick={() => {
                            handlePreSave();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end card header */}
                  <form onSubmit={handleSubmit} className='card-body'>
                    <div className='live-preview'>
                      <div className='row gy-4'>
                        {/*TextField start*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='firstName'
                              value={tenantProfile.firstName}
                              label='First Name'
                              error={errors.firstName}
                              onChange={handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*TextField end*/}

                        {/*TextField start*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='middleName'
                              value={tenantProfile.middleName}
                              label='Middle Name'
                              error={errors.middleName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/*TextField end*/}
                        {/*end col*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='lastName'
                              value={tenantProfile.lastName}
                              label='Last Name'
                              error={errors.lastName}
                              onChange={handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='emailAddress'
                              value={tenantProfile.emailAddress}
                              label='Email Address'
                              error={errors.emailAddress}
                              onChange={handleChange}
                              type={'email'}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <Input
                              showIcon
                              name='dateOfBirth'
                              value={tenantProfile.dateOfBirth}
                              label='Birth Date'
                              error={errors.dateOfBirth}
                              onChange={handleChange}
                              type={'date'}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className='col-xxl-3 col-md-6'>
                          <PasswordInput
                            name='ssnTrn'
                            label='SSN/TRN'
                            value={tenantProfile.ssnTrn}
                            error={errors.ssnTrn}
                            onChange={handleChange}
                            showPassword={showSsnTrn}
                            setShowPassword={setshowSsnTrn}
                          />
                        </div>

                        <div className='col-xxl-3 col-md-6'>
                          <PasswordInput
                            name='driversLicenseNumber'
                            label='Drivers License Number'
                            value={tenantProfile.driversLicenseNumber}
                            error={errors.driversLicenseNumber}
                            onChange={handleChange}
                            showPassword={showDriversLicenseNumber}
                            setShowPassword={setshowDriversLicenseNumber}
                          />
                        </div>
                        {/*end col*/}

                        {/*Cellphone */}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='cellPhoneNumber'
                              value={tenantProfile.cellPhoneNumber}
                              label='Cell Phone Number'
                              error={errors.cellPhoneNumber}
                              onChange={handleChange}
                              type={'text'}
                            />
                          </div>
                        </div>
                        {/*end col*/}

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='workPhoneNumber'
                              value={tenantProfile.workPhoneNumber}
                              label='Work Phone Number'
                              error={errors.workPhoneNumber}
                              onChange={handleChange}
                              type={'text'}
                            />
                          </div>
                        </div>
                        {/*end col*/}

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='durationOfCurrentResidence'
                              value={
                                tenantProfile &&
                                tenantProfile.preScreeningDetail
                                  ? tenantProfile.preScreeningDetail
                                      .durationOfCurrentResidence
                                  : ''
                              }
                              label='Duration of Current Residence (in months)'
                              error={errors.durationOfCurrentResidence}
                              onChange={handleChange}
                              type={'number'}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='totalMonthlyIncome'
                              value={
                                tenantProfile &&
                                tenantProfile.preScreeningDetail
                                  ? tenantProfile.preScreeningDetail
                                      .totalMonthlyIncome
                                  : ''
                              }
                              label='Total Monthly Income'
                              error={errors.totalMonthlyIncome}
                              onChange={handleChange}
                              type={'number'}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='sourceOfIncome'
                              value={
                                tenantProfile &&
                                tenantProfile.preScreeningDetail
                                  ? tenantProfile.preScreeningDetail
                                      .sourceOfIncome
                                  : ''
                              }
                              label='Source of Income'
                              error={errors.sourceOfIncome}
                              onChange={handleChange}
                              type={'text'}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='numberOfOccupants'
                              value={
                                tenantProfile &&
                                tenantProfile.preScreeningDetail
                                  ? tenantProfile.preScreeningDetail
                                      .numberOfOccupants
                                  : ''
                              }
                              label='Total Number of Occupants'
                              error={errors.numberOfOccupants}
                              onChange={handleChange}
                              type={'number'}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <TextInputLabel
                              name='reasonForLeaving'
                              value={
                                tenantProfile &&
                                tenantProfile.preScreeningDetail
                                  ? tenantProfile.preScreeningDetail
                                      .reasonForLeaving
                                  : ''
                              }
                              label='Reason for Leaving'
                              error={errors.reasonForLeaving}
                              onChange={handleChange}
                              type={'textarea'}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}

                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <CheckboxInput
                              name='doesAnyOneSmoke'
                              label='Does Any of the Occupants Smoke?'
                              checked={
                                tenantProfile &&
                                tenantProfile.preScreeningDetail
                                  ? tenantProfile.preScreeningDetail
                                      .doesAnyOneSmoke
                                  : false
                              }
                              onChange={handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className='col-xxl-3 col-md-6'>
                          <div>
                            <CheckboxInput
                              name='hasPets'
                              label='Do you have any pets?'
                              checked={
                                tenantProfile &&
                                tenantProfile.preScreeningDetail
                                  ? tenantProfile.preScreeningDetail.hasPets
                                  : false
                              }
                              onChange={handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        {tenantProfile &&
                          tenantProfile.preScreeningDetail &&
                          tenantProfile.preScreeningDetail.hasPets && (
                            <div className='col-xxl-3 col-md-6'>
                              <div>
                                <TextInputLabel
                                  name='describePets'
                                  value={
                                    tenantProfile.preScreeningDetail
                                      .describePets
                                  }
                                  label='Please Describe Your Pets'
                                  error={errors.describePets}
                                  onChange={handleChange}
                                  type={'textarea'}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          )}
                        {/*end col*/}
                      </div>
                      {/*end row*/}
                      <CurrentAddressAppliForm
                        tenantProfile={tenantProfile}
                        setTenantProfile={setTenantProfile}
                      />
                      {/* Previous Address DO it the same way*/}
                      {/* Do we need more than one previous addresses?*/}
                      <PreviousAddressAppliForm
                        tenantProfile={tenantProfile}
                        setTenantProfile={setTenantProfile}
                      />
                      <OtherOccupantAppliForm
                        tenantProfile={tenantProfile}
                        setTenantProfile={setTenantProfile}
                      />

                      <EmploymentAndIncomeForm
                        tenantProfile={tenantProfile}
                        setTenantProfile={setTenantProfile}
                      />
                      {/*Register Button*/}
                      <div className='row gy-4' style={{ paddingTop: '2%' }}>
                        <div className='col-lg-12'>
                          <div className='d-grid'>
                            <button
                              className='btn btn-primary'
                              disabled={validate()}
                              type='submit'
                            >
                              Submit (Will be enabled once you have viewed the
                              unit) Save in the meantime
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrimaryApplicationForm;
