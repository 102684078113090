import http from "../../general/httpService";
import config from "../../../constants/config.json";
import jwtDecode from "jwt-decode";
import { firebase_auth } from "../../../firebase";
import CryptoJS from "crypto-js";
import { signInWithEmailAndPassword,createUserWithEmailAndPassword,signOut } from "firebase/auth";
//declaring the constants
const tokenKey = config.token_key;
const firebaseTokenKey = config.frtoken_key;
const email = config.email_key;
const userKey = config.user_key;


//Master copy of the tenant profile and file, the snapshots are created based on this
//update_tenant_profile
export async function updateTenantProfileMainAppli(tenantProfileData) {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenantProfileManagement/api/update_tenant_profile_main_appli`;
  const response = await http.put(fullApiEndpoint, tenantProfileData);

    return response;
}


export function generateKey(tenantProfile) {
  const emailAddressWithoutSpecialCharacters = tenantProfile.emailAddress.replace(/[^a-zA-Z0-9]/g, "");
  return `${tenantProfile.tenantId}_${emailAddressWithoutSpecialCharacters}_${tenantProfile.firstName.toLowerCase()}`;
}

export function decryptData(encryptedData, key) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key);
  return bytes.toString(CryptoJS.enc.Utf8);
}

///api/get_all_application_snapshots_by_tenant/:tenantId
export async function getAllApplicationSnapshotsByTenant(tenantId) {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenantApplicManagement/api/get_all_application_snapshots_by_tenant/${tenantId}`;
  const response = await http.get(fullApiEndpoint);
  return response;
}