import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import loader from "../../../styletheme/assets/img/svgicons/loader.svg";
import profileImg from '../../../styletheme/images/users/multi-user.jpg';
import {
  decryptData,
  generateKey,
  updateTenantProfileMainAppli,
} from '../../../services/tenant/masterApplication/tenantMainApplicationManagement';
import { getCurrentUserProfileByEmail } from '../../../services/pre-screening/prescreeningManagementService';
import { getAllApplicationSnapshotsByTenant } from '../../../services/tenant/masterApplication/tenantMainApplicationManagement';
import config from '../../../constants/config.json';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { get } from 'lodash';
import DashboardMiniFactCard from './sub/dashboardMiniFactCard';

const Dashboard = ({ tenantProfile, isDarkMode }) => {
  const [isLoading, setIsLoading] = useState();
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: 'Relevant news about Gurenter and its offerings',
  });

  //add the viewing date and time
  //need to get the viewing date and time from the database
  //going to have to give availability to the tenant to select a date and time
  const [viewingTourDetails, setViewingTourDetails] = useState({
    viewingDate: '',
    viewingTime: '',
    location: '',
  });

  const [fullTenantProfile, setFullTenantProfile] = useState({
    tenantId: '',
    isTheMainTenant: false,
    hasCompletedApplicationSignUp: false,
    firstName: '',
    middleName: '',
    lastName: '',

    workPhoneNumber: '',
    dateOfBirth: new Date(),
    mainTenantImageUrl: '',
    emailAddress: '',
    //password: "",
    signatureDetail: [],
    currentApplPool: {
      landLordId: '',
      propertyId: '',
      contractId: '',
      inviteAccessCode: '',
      hasAccessCodeBeenUsed: false,
      isApprovedOrRejected: false,
      finalDecision: '',
      applicationStatus: 'Not Started',
    },
    tenantApplication: {
      latestVersion: false,
      applicationLastUpdate: null,
      propertyId: '',
      propertyName: '',
      tenantApplicationSummaryData: {
        numberofOccupants: 0,
        numberOfReferenceAdded: 0,
        combinedSalary: 0,
      },
      rentCost: [],
      startDate: '',
      referredByDetail: [],
      applicantInfo: {
        firstName: '',
        middleInitial: '',
        lastName: '',
        trnSsn: '',
        driverLicense: '',
        dateOfBirth: '',
        homePhone: '',
        workPhone: '',
        emailAddress: '',
      },
      //separate the address into its own collection
      currentAddressDetail: {
        addressType: '',
        streetAddress: '',
        cityTown: '',
        parishState: '',
        countryName: '',
        zipPostalCode: '',
        dateIn: '',
        landLordName: '',
        landLordPhone: '',
        monthlyRent: '',
        recordTimeStamp: null,
      },

      preScreeningDetail: {
        emailAddress: '',
        firstName: '',
        lastName: '',
        durationOfCurrentResidence: 0,
        totalMonthlyIncome: 0,
        sourceOfIncome: '',
        numberOfOccupants: 0,
        reasonForLeaving: '',
        desiredMoveInDate: new Date(),
        doesAnyOneSmoke: false,
        hasPets: false,
        describePets: '',
      },
      previousAddressDetail: [],
      otherOccupants: [],
      petsDetail: [],
      employmentIncomeInfo: [],
      otherIncomeSources: [],
      emergencyContact: [],
      personalReferences: [],
      backgroundInfo: [],
      vehicleInfo: [],
    },
    recordCreatedDate: null,
    isPublic: false,
  });

  const [hasPassedInterview, setHasPassedInterview] = useState(false);
  //set the system set this (can set their own wording but not change the action)
  const [todoList, setTodoList] = useState([
    {
      taskName: 'Schedule Viewing',
      action: 'Schedule',
      type: 'link',
      location: 'https://calendly.com/03collis42/unit-30a-apartment-tour-clone', //'/bookviewing',
      isDisabled: false,
    },
    {
      taskName: 'Tour Unit',
      action: 'Complete',
      type: 'internal',
      location: '/completeUnitTour',
      isDisabled: true,
    },
    {
      taskName: 'Sign Up Full Application',
      action: 'Fill Out',
      type: 'internal',
      location: '/primaryApplicationForm',
      isDisabled: false,
    },
    {
      taskName: 'Interview',
      action: 'Get Interview Link',
      type: 'internal',
      location: '/getinterviewlink',
      isDisabled: true,
    },
    {
      taskName: 'Sign Contract',
      action: 'Sign',
      type: 'internal',
      location: '/signcontract',
      needToPassInterview: true, //shows only for those who passed the interview
      isDisabled: true,
    },
    {
      //give ability to enter the transaction number and or upload an image as proof
      taskName: "Pay Security Deposit and First Month's Rent",
      type: 'internal',
      action: 'Proof of Payment',
      needToPassInterview: true,
      isDisabled: true,
    },
  ]);

  const [allContracts, setAllContracts] = useState([
    {
      tenantId: '',
      isTheMainTenant: false,
      hasCompletedApplicationSignUp: false,
      firstName: '',
      middleName: '',
      lastName: '',

      workPhoneNumber: '',
      dateOfBirth: new Date(),
      mainTenantImageUrl: '',
      emailAddress: '',
      //password: "",
      signatureDetail: [],
      currentApplPool: {
        landLordId: '',
        propertyId: '',
        contractId: '',
        inviteAccessCode: '',
        hasAccessCodeBeenUsed: false,
        isApprovedOrRejected: false,
        finalDecision: '',
        applicationStatus: 'Not Started',
      },
      tenantApplication: {
        latestVersion: false,
        applicationLastUpdate: null,
        propertyId: '',
        propertyName: '',
        tenantApplicationSummaryData: {
          numberofOccupants: 0,
          numberOfReferenceAdded: 0,
          combinedSalary: 0,
        },
        rentCost: [],
        startDate: '',
        referredByDetail: [],
        applicantInfo: {
          firstName: '',
          middleInitial: '',
          lastName: '',
          trnSsn: '',
          driverLicense: '',
          dateOfBirth: '',
          homePhone: '',
          workPhone: '',
          emailAddress: '',
        },
        //separate the address into its own collection
        currentAddressDetail: {
          addressType: '',
          streetAddress: '',
          cityTown: '',
          parishState: '',
          countryName: '',
          zipPostalCode: '',
          dateIn: '',
          landLordName: '',
          landLordPhone: '',
          monthlyRent: '',
          recordTimeStamp: null,
        },
        preScreeningDetail: {
          emailAddress: '',
          firstName: '',
          lastName: '',
          durationOfCurrentResidence: 0,
          totalMonthlyIncome: 0,
          sourceOfIncome: '',
          numberOfOccupants: 0,
          reasonForLeaving: '',
          desiredMoveInDate: new Date(),
          doesAnyOneSmoke: false,
          hasPets: false,
          describePets: '',
        },
        //make it into its own collection
        previousAddressDetail: [],
        otherOccupants: [],
        petsDetail: [],
        employmentIncomeInfo: [],
        otherIncomeSources: [],
        emergencyContact: [],
        personalReferences: [],
        backgroundInfo: [],
        vehicleInfo: [],
      },
      recordCreatedDate: null,
      isPublic: false,
      propertyUnitAdvertisementListing: {
        advertId: '',
        landlordId: '',
        propertyUnitId: '',
        dateAdvertEnds: '',
        campaignId: '',
        propertyUnit: {
          propertyUnitId: '',
          rentalPropertyId: '',
          unitName: '',
          rentReceiptSequence: 0,
          rentalUnitPrice: 0,
          rentalUnitAmtCurrency: 'USD',
          unitType: '',
          unitCurrentRentalStatus: 'Vacant',
          unitSpecifications: {
            numberOfBedrooms: 0,
            unitSquareFootage: 0,
            parkingDetail: {
              drivewayType: '', //paved, gravel, dirt
              parkingSpace: 0,
            },
            numberOfRooms: 0,
            numberOfBathrooms: 0,
            numberOfHalfBathroom: 0,

            hasKitchen: false,
            hasPatios: false,
            hasPorch: false,
            hasDiningRoom: false,
            hasHalfBathrooms: false,
            hasLivingRoom: false,
            hasCarGarage: false,
          },
          petSpecifications: {
            allowPets: false,
            maxNumberOfPetsAllowed: 0,
            petsAllowed: [
              {
                animalName: '',
                animalType: '',
                animalWeightMax: 0,
              },
            ],
          },
          dateLastRented: '',
          recordCreatedDate: '',
          isRented: false,
          isActivated: true,
        },
        unitAdvertImageUrls: [''],
        securityDeposit: 0,
        currencyId: '',
        currencyName: '',
        isAdvertExpired: false,
      },
    },
  ]);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);

    //get the tenant profile
    getTenantProfile();

    //after loading

    setIsLoading(false);
  }, []);

  const getTenantProfile = async () => {
    //get the tenant profile
    const email = localStorage.getItem(config.email_key);
    //alert(email);
    console.log(email);
    const { data: fullTenantProfile } = await getCurrentUserProfileByEmail(
      email
    );

    const transportKey = generateKey(fullTenantProfile);

    if (tenantProfile.ssnTrn) {
      fullTenantProfile.ssnTrn = decryptData(
        fullTenantProfile.ssnTrn,
        transportKey
      );
    }
    if (fullTenantProfile.driversLicenseNumber) {
      fullTenantProfile.driversLicenseNumber = decryptData(
        fullTenantProfile.driversLicenseNumber,
        transportKey
      );
    }

    if (fullTenantProfile.dateOfBirth) {
      const decryptedDateOfBirth = await decryptData(
        fullTenantProfile.dateOfBirth,
        transportKey
      );

      //TODO: not being set
      fullTenantProfile.dateOfBirth = decryptedDateOfBirth;
    }

    console.log('Work on Current address section next');

    //show prescreening
    console.log(fullTenantProfile.preScreeningDetail);

    //set the tenantId so i can get all the contracts
    localStorage.setItem(config.user_key, fullTenantProfile.tenantId);

    const tenantId = localStorage.getItem(config.user_key);
    console.log(tenantId);
    getAllApplicationSnapshots(tenantId);

    //set the tenant profile

    setFullTenantProfile(fullTenantProfile);
  };

  //get all contracts
  //getAllApplicationSnapshotsByTenant
  const getAllApplicationSnapshots = async (tenantId) => {
    //get all the contracts
    const { data: allContracts } = await getAllApplicationSnapshotsByTenant(
      tenantId
    );

    console.log(allContracts);

    setAllContracts(allContracts);
    //setAllContracts
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {isLoading && (
        <div id='global-loader'>
          {/*	<img src={loader} className='loader-img' alt='Loader' /> */}
        </div>
      )}
      {/*/Loader */}

      <div
        className='page-content'
        data-bs-theme={isDarkMode ? 'dark' : 'light'}
      >
        <div className='container-fluid'>
          {/* start page title */}
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                <h4 className='mb-sm-0'>Tenant Home</h4>

                <div className='page-title-right'>
                  <ol className='breadcrumb m-0'>
                    <li className='breadcrumb-item'>
                      <a
                        onClick={() => {
                          /* toggle class logic */
                        }}
                      >
                        Dashboards
                      </a>
                    </li>
                    <li className='breadcrumb-item active'>Tenant</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}

          <div className='row'>
            <div className='col-xxl-5'>
              <div className='d-flex flex-column h-100'>
                <div className='row h-100'>
                  <div className='col-12'>
                    <div className='card'>
                      <div className='card-body p-0'>
                        <div class='pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper'>
                          <div class='row g-4'>
                            <div class='col-auto'>
                              <div class='avatar-lg'>
                                <img
                                  src={profileImg}
                                  alt='user-img'
                                  class='img-thumbnail rounded-circle'
                                />
                              </div>
                            </div>
                            {/*end col*/}
                            <div class='col'>
                              <div class='p-2'>
                                <h3 class='card-title mb-1'>
                                  {tenantProfile.firstName}{' '}
                                  {tenantProfile.lastName}
                                </h3>
                                <p class='card-subtitle text-opacity-75'>
                                  Tenant
                                </p>
                                <div class='hstack  gap-1'>
                                  <div class='me-2'>
                                    <i class='ri-user-line me-1 text-opacity-75 fs-16 align-middle'></i>
                                    {fullTenantProfile &&
                                    fullTenantProfile.preScreeningDetail
                                      ? fullTenantProfile.preScreeningDetail
                                          .numberOfOccupants
                                      : ''}
                                  </div>
                                  <div>
                                    <i class='ri-money-dollar-circle-line fs-16 align-middle'></i>
                                    {fullTenantProfile.preScreeningDetail &&
                                    fullTenantProfile.preScreeningDetail
                                      .totalMonthlyIncome
                                      ? fullTenantProfile.preScreeningDetail
                                          .totalMonthlyIncome
                                      : ''}
                                  </div>

                                  <div>
                                    <i class='ri-profile-line fs-16 align-middle'></i>
                                    {fullTenantProfile.preScreeningDetail &&
                                    fullTenantProfile.preScreeningDetail
                                      .sourceOfIncome
                                      ? fullTenantProfile.preScreeningDetail
                                          .sourceOfIncome
                                      : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*end col*/}
                            <div class='col-12 col-lg-auto order-last order-lg-0'>
                              <div class='row text  text-center'>
                                <div class='col-lg-6 col-4'>
                                  <div class='p-2'></div>
                                </div>
                                <div class='col-lg-6 col-4'>
                                  <div class='p-2'></div>
                                </div>
                              </div>
                            </div>
                            {/*end col*/}
                          </div>
                          {/*end row*/}
                        </div>
                      </div>
                      {/* end card-body*/}
                    </div>
                  </div>
                  {/* end col*/}
                </div>
                {/* end row*/}

                <div className='row h-100'>
                  <div className='card'>
                    <div className='col-12'>
                      {/*Tab start*/}
                      <Tabs
                        defaultActiveKey='pending'
                        id='fill-tab-example'
                        className='mb-3'
                        fill
                      >
                        <Tab eventKey='active' title='Active'>
                          {/* Active shows here*/}
                        </Tab>
                        <Tab eventKey='pending' title='Pending'>
                          {/* Tab content for Profile NEXT here i will add the
                          contracts to be able to access then the list of list
                          of addresses as a list then re-save them as a
                                    sub-collection as not to destroy the mongodb structure*/}
                          <div class='row'>
                            <div class=''>
                              {allContracts.map((item, index) => (
                                <>
                                  <div class='card card-height-100'>
                                    <div class='card-body'>
                                      <div class='d-flex flex-column h-100'>
                                        <div
                                          class={`p-3 mt-n3 mx-n3  bg-${
                                            item.currentApplPool.applicationStatus
                                              .toLowerCase()
                                              .includes('passed')
                                              ? 'success-subtle rounded-top'
                                              : 'danger-subtle rounded-top'
                                          }`}
                                        >
                                          <div class='d-flex align-items-center'>
                                            <div class='flex-grow-1'>
                                              <h5 class='mb-0 fs-14'>
                                                <a
                                                  href='apps-projects-overview.html'
                                                  class='text-body'
                                                >
                                                  {
                                                    item.currentApplPool
                                                      .finalDecision
                                                  }
                                                </a>
                                              </h5>
                                            </div>
                                            <div class='flex-shrink-0'>
                                              <h5 class='d-flex gap-1 align-items-center my-n2'>
                                                <span
                                                  className={`badge bg-${
                                                    item.currentApplPool.applicationStatus
                                                      .toLowerCase()
                                                      .includes('passed')
                                                      ? 'success'
                                                      : 'danger'
                                                  }`}
                                                >
                                                  {
                                                    item.currentApplPool
                                                      .applicationStatus
                                                  }
                                                </span>
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class='d-flex mb-2'
                                          style={{ paddingTop: '10px' }}
                                        >
                                          <div class='flex-shrink-0 me-2'>
                                            <div class='avatar-xl'>
                                              <span class='avatar-title bg-warning-subtle rounded p-2'>
                                                {Array.isArray(
                                                  item
                                                    .propertyUnitAdvertisementListing
                                                    .propertyUnit
                                                    .insideOfUnitImageUrls
                                                ) &&
                                                item
                                                  .propertyUnitAdvertisementListing
                                                  .propertyUnit
                                                  .insideOfUnitImageUrls
                                                  .length > 0 ? (
                                                  <img
                                                    src={
                                                      item
                                                        .propertyUnitAdvertisementListing
                                                        .propertyUnit
                                                        .insideOfUnitImageUrls[0]
                                                        .imageUrl
                                                    }
                                                    alt=''
                                                    class='img-fluid'
                                                  />
                                                ) : (
                                                  <img
                                                    src='assets/images/brands/slack.png'
                                                    alt=''
                                                    class='img-fluid p-1'
                                                  />
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          <div class='flex-grow-1'>
                                            <h1 class='mb-1 fs-21'>
                                              {item.propertyUnitAdvertisementListing
                                                ? item
                                                    .propertyUnitAdvertisementListing
                                                    .propertyUnit.unitName
                                                : ''}
                                            </h1>
                                            <p class='text-muted text-truncate-two-lines mb-3'>
                                              {item.propertyUnitAdvertisementListing
                                                ? item
                                                    .propertyUnitAdvertisementListing
                                                    .propertyUnit.unitType
                                                : ''}
                                            </p>

                                            <div class='d-flex flex-wrap gap-2 fs-16'>
                                              {/* Card Fact start*/}

                                              <DashboardMiniFactCard
                                                imgUrl={
                                                  'las la-ruler-combined la-3x'
                                                }
                                                label={'Square Footage'}
                                                value={
                                                  item
                                                    .propertyUnitAdvertisementListing
                                                    ?.propertyUnit
                                                    ?.unitSpecifications
                                                    ?.unitSquareFootage
                                                }
                                                valueLabel={'sqft'}
                                              />

                                              {/* Card Fact end */}

                                              <DashboardMiniFactCard
                                                imgUrl={'las la-bed la-3x'}
                                                label={'Bedrooms'}
                                                value={
                                                  item
                                                    .propertyUnitAdvertisementListing
                                                    ?.propertyUnit
                                                    ?.unitSpecifications
                                                    ?.numberOfBedrooms
                                                }
                                                valueLabel={''}
                                              />

                                              <DashboardMiniFactCard
                                                imgUrl={'las la-toilet la-3x'}
                                                label={'Bathrooms'}
                                                value={
                                                  item
                                                    .propertyUnitAdvertisementListing
                                                    ?.propertyUnit
                                                    ?.unitSpecifications
                                                    ?.numberOfBathrooms
                                                }
                                                valueLabel={''}
                                              />

                                              <DashboardMiniFactCard
                                                imgUrl={'las la-parking la-3x'}
                                                label={'Parking Spaces'}
                                                value={
                                                  item
                                                    .propertyUnitAdvertisementListing
                                                    ?.propertyUnit
                                                    ?.unitSpecifications
                                                    ?.parkingDetail
                                                    ?.parkingSpace
                                                }
                                                valueLabel={''}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* end card body */}
                                    <div class='card-footer bg-transparent border-top-dashed py-2'>
                                      <div class='d-flex align-items-center'>
                                        <div class='flex-grow-1'>
                                          {/* Add data here*/}
                                          <h5>
                                            <i class='ri-money-dollar-circle-line me-1 align-bottom'></i>{' '}
                                            {
                                              item
                                                .propertyUnitAdvertisementListing
                                                .propertyUnit.rentalUnitPrice
                                            }{' '}
                                            {
                                              item
                                                .propertyUnitAdvertisementListing
                                                .propertyUnit
                                                .rentalUnitAmtCurrency
                                            }
                                          </h5>
                                        </div>
                                        <div class='flex-shrink-0'>
                                          <div class='text-muted'>
                                            {/* Add data here*/}
                                            <button
                                              type='button'
                                              class='btn btn-outline-secondary waves-effect waves-light'
                                              disabled
                                            >
                                              Detail
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* end card footer */}
                                  </div>
                                  {/* end card */}
                                </>
                              ))}
                            </div>
                            {/* end col */}
                          </div>
                        </Tab>
                        <Tab eventKey='past' title='Past'>
                          {/* Past shows here*/}
                        </Tab>
                      </Tabs>
                      {/*Tab end*/}
                    </div>
                  </div>
                </div>

                {/* end row*/}
              </div>
            </div>
            {/* end col*/}

            <div className='col-xxl-7'>
              <div className='row h-100'>
                <div className='col-xl-6'>
                  <div className='card card-height-100'>
                    <div className='card-header align-items-center d-flex'>
                      <h4 className='card-title mb-0 flex-grow-1'>
                        My Todo List
                      </h4>
                      <div hidden>
                        <button
                          type='button'
                          className='btn btn-soft-secondary btn-sm'
                        >
                          ALL
                        </button>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm'
                        >
                          1M
                        </button>
                        <button
                          type='button'
                          className='btn btn-soft-secondary btn-sm'
                        >
                          6M
                        </button>
                      </div>
                    </div>
                    <div hidden className='card-body p-0'>
                      <div>
                        {/*table start*/}
                        <div class='todo-task' id='todo-task'>
                          <div class='table-responsive'>
                            <table class='table align-middle position-relative table-nowrap'>
                              <thead class='table-active'>
                                <tr>
                                  <th scope='col'>Task Name</th>

                                  <th scope='col'>Action</th>
                                </tr>
                              </thead>

                              <tbody id='task-list'>
                                {todoList.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div class='d-flex align-items-start'>
                                        <div>
                                          <div class='form-check'>
                                            <input
                                              class='form-check-input'
                                              type='checkbox'
                                              value='9'
                                              id={`todo${index}`}
                                              disabled={
                                                (!hasPassedInterview &&
                                                  item.needToPassInterview) ||
                                                item.isDisabled
                                              }
                                              style={{
                                                opacity:
                                                  !hasPassedInterview &&
                                                  item.needToPassInterview
                                                    ? 0.5
                                                    : 1,
                                                cursor:
                                                  !hasPassedInterview &&
                                                  item.needToPassInterview
                                                    ? 'not-allowed'
                                                    : 'default',
                                              }}
                                            />{' '}
                                            <label
                                              class='form-check-label'
                                              for={`todo${index}`}
                                            >
                                              {item.taskName}
                                            </label>{' '}
                                          </div>{' '}
                                        </div>{' '}
                                      </div>{' '}
                                    </td>{' '}
                                    <td>
                                      <div class='hstack gap-2'>
                                        <button
                                          hidden
                                          class='btn btn-sm btn-soft-danger remove-list'
                                          data-bs-toggle='modal'
                                          data-bs-target='#removeTaskItemModal'
                                          data-remove-id='9'
                                        >
                                          <i class='ri-delete-bin-5-fill align-bottom'></i>
                                        </button>{' '}
                                        {item.type === 'link' ? (
                                          <>
                                            <a
                                              className='btn btn-sm btn-soft-info edit-list'
                                              data-edit-id='9'
                                              target='_blank'
                                              href={item.location}
                                              onClick={(e) => {
                                                if (
                                                  (!hasPassedInterview &&
                                                    item.needToPassInterview) ||
                                                  (allContracts &&
                                                    allContracts[0] &&
                                                    allContracts[0]
                                                      .currentApplPool &&
                                                    allContracts[0]
                                                      .currentApplPool
                                                      .applicationStatus &&
                                                    (allContracts[0].currentApplPool.applicationStatus
                                                      .toLowerCase()
                                                      .includes('failed') ||
                                                      allContracts[0].currentApplPool.applicationStatus
                                                        .toLowerCase()
                                                        .includes('reject'))) ||
                                                  !allContracts[0]
                                                    .currentApplPool
                                                    .isApprovedOrRejected
                                                ) {
                                                  e.preventDefault();
                                                }
                                              }}
                                              style={{
                                                opacity:
                                                  (!hasPassedInterview &&
                                                    item.needToPassInterview) ||
                                                  (allContracts &&
                                                    allContracts[0] &&
                                                    allContracts[0]
                                                      .currentApplPool &&
                                                    allContracts[0]
                                                      .currentApplPool
                                                      .applicationStatus &&
                                                    (allContracts[0].currentApplPool.applicationStatus
                                                      .toLowerCase()
                                                      .includes('failed') ||
                                                      allContracts[0].currentApplPool.applicationStatus
                                                        .toLowerCase()
                                                        .includes('reject'))) ||
                                                  !allContracts[0]
                                                    .currentApplPool
                                                    .isApprovedOrRejected
                                                    ? 0.5
                                                    : 1,
                                                cursor:
                                                  (!hasPassedInterview &&
                                                    item.needToPassInterview) ||
                                                  (allContracts &&
                                                    allContracts[0] &&
                                                    allContracts[0]
                                                      .currentApplPool &&
                                                    allContracts[0]
                                                      .currentApplPool
                                                      .applicationStatus &&
                                                    (allContracts[0].currentApplPool.applicationStatus
                                                      .toLowerCase()
                                                      .includes('failed') ||
                                                      allContracts[0].currentApplPool.applicationStatus
                                                        .toLowerCase()
                                                        .includes('reject'))) ||
                                                  !allContracts[0]
                                                    .currentApplPool
                                                    .isApprovedOrRejected
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                              }}
                                            >
                                              <i className='ri-pencil-fill align-bottom'></i>{' '}
                                              {item.action}
                                            </a>
                                          </>
                                        ) : (
                                          <Link
                                            className='btn btn-sm btn-soft-info edit-list'
                                            data-bs-toggle='modal'
                                            data-bs-target='#createTask'
                                            data-edit-id='9'
                                            target='_blank'
                                            to={
                                              item.isDisabled
                                                ? '#'
                                                : item.location
                                            }
                                            onClick={(e) => {
                                              if (
                                                item.isDisabled ||
                                                (!hasPassedInterview &&
                                                  item.needToPassInterview)
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                            style={{
                                              opacity:
                                                item.isDisabled ||
                                                (!hasPassedInterview &&
                                                  item.needToPassInterview)
                                                  ? 0.5
                                                  : 1,
                                              cursor:
                                                item.isDisabled ||
                                                (!hasPassedInterview &&
                                                  item.needToPassInterview)
                                                  ? 'not-allowed'
                                                  : 'pointer',
                                            }}
                                          >
                                            <i className='ri-pencil-fill align-bottom'></i>{' '}
                                            {item.action}
                                          </Link>
                                        )}
                                      </div>{' '}
                                    </td>{' '}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/*table end*/}
                      </div>
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col*/}
              </div>
              {/* end row*/}
            </div>
            {/* end col */}
          </div>
          {/* end row*/}
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </React.Fragment>
  );
};

export default Dashboard;
