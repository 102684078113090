import React, { Component } from "react";
import {
  logout,
  logoutWithFirebase,
} from "../../../services/pre-screening/prescreeningManagementService";

class LogOut extends Component {
  componentDidMount() {
    //remove token from local storage
    logout();

    logoutWithFirebase();

    //redirect user home page (force refresh)
    window.location = "/";
  }

  render() {
    return null;
  }
}

export default LogOut;
