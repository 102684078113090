//Register landlord next and let the questions come from the api

import React, { useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

//import load_img from "../../../styletheme/assets/img/svgicons/loader.png";
//import fav_img from "../../../styletheme/assets/img/brand/favicon.png";

import { Bars } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Form from '../../reusable/form/form';
import Joi from 'joi-browser';

import Input from '../../reusable/form/Input';

import { toast } from 'react-toastify';
import { loginTenant } from '../../../services/pre-screening/prescreeningManagementService';

//import "react-toastify/dist/ReactToastify.css";
//TODO: set the background to be gray so that the nav bar is visble
const LoginPage = () => {
  //for login
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const navigate = useNavigate();
  const [data, setData] = useState({
    emailAddress: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  const schema = {
    emailAddress: Joi.string().email().required().label('Email Address'), //use joi in node as well
    password: Joi.string().min(7).required().label('Password'),
  };

  //this is the home page (only authenticated people)
  const [pageHeaderDetail, setHeaderDetail] = useState({
    title: 'Relevant news about Gurenter and its offerings',
  });

  const [Loading, setLoading] = useState({
    isLoading: false,
  });

  useEffect(() => {
    setLoading({ isLoading: true });
    window.scrollTo(0, 0);

    //after loading
    setLoading({ isLoading: false });
  }, []);

  //TODO: Form Validation (Need to find a way to make this more streamlined)
  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };
  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaLocal = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();
    //console.log(errors);

    //toast.error(errors.password);
    setErrors({ errors: errors || {} });
    if (errors) return;

    doSubmit();
  };

  const handleChange = ({ currentTarget: input }) => {
    //input validation

    const errorMessage = validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    //data seccount (model)
    //const data = { ...data };

    data[input.name] = input.value;

    //toast.error(input.value);
    //setData({ data: data });
    setErrors({ errors });
  };

  const doSubmit = async () => {
    //login function
    try {
      //const { data } = this.state;
      const loginPayLoad = {
        emailAddress: data.emailAddress,
        password: data.password,
      };

      //console.log(loginPayLoad);
      //alert("Login called");
      //return;
      await loginTenant(loginPayLoad);

      //get and set the profile

      //Not working
      // alert(`Login called ${test}`);

      toast.success('Logged In Successfully');

      //force update
      forceUpdate();

      //force a reload of the page
      window.location = '/dashboard';

      navigate('/dashboard');
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.emailAddress = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };

  return (
    <React.Fragment>
      {/*Loader */}
      {Loading.isLoading && (
        <div
          id='global-loader'
          style={{
            zIndex: '99',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'absolute',
          }}
        >
          <Bars
            height='80'
            width='80'
            color='#4fa94d'
            ariaLabel='bars-loading'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
          />
        </div>
      )}
      {/*/Loader */}

      {/*Page */}
      <div className='page'>
        <div className='container-fluid'>
          <div className='row no-gutter'>
            {/*The image half */}
            <div className='col-md-6 col-lg-6 col-xl-7 d-none d-md-flex bg-primary-transparent'>
              <div className='row wd-100p mx-auto text-center'>
                <div className='col-md-12 col-lg-12 col-xl-12 my-auto mx-auto wd-100p'></div>
              </div>
            </div>
            {/*The content half */}
            <div className='col-md-6 col-lg-6 col-xl-5 bg-white py-4'>
              <div className='login d-flex align-items-center py-2'>
                {/*Demo content*/}
                <div className='container p-0'>
                  <div className='row'>
                    <div className='col-md-10 col-lg-10 col-xl-9 mx-auto'>
                      <div className='card-sigin'>
                        <div className='mb-5 d-flex'>
                          <h1 className='main-logo1 ms-1 me-0 my-auto tx-28'>
                            Gu<span>re</span>nter Tenant
                          </h1>
                        </div>
                        <div className='card-sigin'>
                          <div className='main-signup-header'>
                            <h2>Welcome back!</h2>
                            <h5 className='fw-semibold mb-4'>
                              Please sign in to continue.
                            </h5>
                            <form onSubmit={handleSubmit}>
                              <div className='form-group'>
                                <Input
                                  name='emailAddress'
                                  type='email'
                                  error={errors.emailAddress}
                                  value={data.emailAddress}
                                  placeholder='Write your Email Address.'
                                  label='Email Address'
                                  onChange={handleChange}
                                />
                              </div>
                              <div className='form-group'>
                                <Input
                                  name='password'
                                  type='password'
                                  error={errors.password}
                                  value={data.password}
                                  placeholder='Write your Password.'
                                  label='Password'
                                  onChange={handleChange}
                                />
                              </div>
                              <button
                                //disabled={validate()}
                                class='btn btn-success w-100'
                              >
                                Sign In
                              </button>
                              <div className='row row-xs' hidden>
                                <div className='col-sm-6'>
                                  <button className='btn btn-block'>
                                    <i className='fab fa-facebook-f'></i> Signup
                                    with Facebook
                                  </button>
                                </div>
                                <div className='col-sm-6 mg-t-10 mg-sm-t-0'>
                                  <button className='btn btn-info btn-block btn-b'>
                                    <i className='fab fa-twitter'></i> Signup
                                    with Twitter
                                  </button>
                                </div>
                              </div>
                            </form>
                            <div hidden className='main-signin-footer mt-5'>
                              <p>
                                <a href='forgot.html'>Forgot password?</a>
                              </p>
                              <p>
                                Don't have an account?{' '}
                                <a href='signup.html'>Create an Account</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*End */}
              </div>
            </div>
            {/*End */}
          </div>
        </div>
      </div>
      {/*End Page */}
    </React.Fragment>
  );
};

export default LoginPage;
