//handles the login and registration
import http from "../general/httpService";
import config from "../../constants/config.json";
import jwtDecode from "jwt-decode";
import { firebase_auth } from "../../firebase";
import { signInWithEmailAndPassword,createUserWithEmailAndPassword,signOut } from "firebase/auth";
//declaring the constants
const tokenKey = config.token_key;
const firebaseTokenKey = config.frtoken_key;
const email = config.email_key;
const userKey = config.user_key;

//Need to send the data to the server , prescreening model, 
//firebase account, the go to the portal

http.setJwt(getJwt());

//need a LOGIN
export async function loginTenant(accountPayLoad) {
    try{
        console.log("Logging in to firebase");
        console.log(`Email: ${accountPayLoad.emailAddress} Password: ${accountPayLoad.password}`);

    //    const userCredential = await signInWithEmailAndPassword(firebase_auth, accountPayLoad.emailAddress, accountPayLoad.password);
     //   console.log("User authenticated successfully:", userCredential);

        // Get the user token
      //  const firebaseUserToken = userCredential.user.accessToken;

        const fullApiEndpoint = config.apiEndpoint + "/tenantProfileManagement/api/login_tenant_web";
        const {data: jwt} = await http.post(fullApiEndpoint, accountPayLoad);

       // localStorage.setItem(firebaseTokenKey, firebaseUserToken);
        

        // const userData = response.data;

   

       // const token = response.headers['x-auth-token'];

       
        localStorage.setItem(tokenKey, jwt);
        localStorage.setItem(email, accountPayLoad.emailAddress);
        //localStorage.setItem(email, accountPayLoad.emailAddress);

        //return userData;
    }
    catch(ex){
        console.log(ex);
    }

}

//Initial registration
//add the create firebase later
export async function createTenantPreScreening(prescreeningData) {
  const fullApiEndpoint =
    config.apiEndpoint + `/tenantApplicManagement/api/create_tenant_pre_screen_and_snapshot_application_form`;
  const response = await http.post(fullApiEndpoint, prescreeningData);
  //print the response
   // Extract the landlordData from the response
    const userData = response.data;

    console.log("API Response");
    console.log(userData);

    const token = response.headers['x-auth-token'];

    console.log(token);
    localStorage.setItem(tokenKey, token);
    localStorage.setItem(email, userData.emailAddress);

    return response;

}

export function getCurrentUserProfileById(tenantId) {
	const fullApiEndpoint =
		config.apiEndpoint + `/tenantProfileManagement/api/get_tenant_by_id/${tenantId}`;
	return http.get(fullApiEndpoint);
}

export function getCurrentUserProfileByEmail(emailAddress) {
     const token = localStorage.getItem(tokenKey);
	const fullApiEndpoint =
		config.apiEndpoint +
		`/tenantProfileManagement/api/get_tenant_by_email/${emailAddress}`;
 
	return http.get(fullApiEndpoint, {
    headers: {
      'x-auth-token': token
    }
  });
}




export function loginWithJwt(jwt) {
	localStorage.setItem(tokenKey, jwt);
}

export function logout() {
	localStorage.removeItem(tokenKey);
	localStorage.removeItem(firebaseTokenKey);
	localStorage.removeItem(email);
	localStorage.removeItem(userKey);

}

export function getCurrentUser() {
	try {
		//get current user
		const jwt = localStorage.getItem(tokenKey);
		//alert(jwt);
		//if its an object
		//return jwtDecode(jwt);
		return jwt;
	} catch (ex) {
		return null;
	}
}

export function getJwt() {
	return localStorage.getItem(tokenKey);
}


export function logoutWithFirebase() {
    //return firebase_auth.signOut();

	signOut(firebase_auth).then(() => {
	// Sign-out successful.
		console.log("Sign out fire successful");
	}).catch((error) => {
	// An error happened.
	});
}