import React from 'react';

const DashboardMiniFactCard = ({ imgUrl, label, value, valueLabel }) => {
  return (
    <div className='badge fw-medium text-primary'>
      <div className='d-flex mb-2 align-items-center'>
        <div className='flex-shrink-0'>
          <i className={imgUrl}></i>
        </div>
        <div className='flex-grow-1 ms-1'>
          <p className='text-muted mb-0'>{label}</p>
          <h6 className='card-title mb-1'>
            {value} {valueLabel}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default DashboardMiniFactCard;
