import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TextInputLabel = ({
  name,
  label,
  className = "form-control",
  placeholder,
  labelClass,
  isRequired,
  iconClassName,
  value,
  onChange,
  type,
  error,
  rows, // add rows prop for textarea
  selectedDate, // add selectedDate prop for date picker
  onDateChange, // add onDateChange prop for date picker
  disabled = false, // change isDisabled to disabled
}) => {
  return (
    <div className="input-box">
      <label className={labelClass}>
        {label}
        {isRequired && <span className="primary-color-2 ml-1"> * </span>}
      </label>
      <div className="form-group">
        {type === "number" ? (
          <input
            className={className}
            type="number"
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            min={1}
            disabled={disabled} // change isDisabled to disabled
          />
        ) : type === "textarea" ? ( // add condition for textarea
          <textarea
            className={className}
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            rows={rows} // add rows attribute
            disabled={disabled} // change isDisabled to disabled
          />
        ) : type === "date" ? ( // add condition for date picker
          <input
            className={className}
            name={name}
            id={name}
            placeholdertext={placeholder}
            selected={selectedDate}
            onChange={onDateChange}
            type="date"
            disabled={disabled} // change isDisabled to disabled
          />
        ) : (
          <input
            className={className}
            type={type}
            name={name}
            id={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            disabled={disabled} // change isDisabled to disabled
          />
        )}
        <span className={iconClassName} style={{ color: "green" }}></span>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default TextInputLabel;
